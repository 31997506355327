@import '../../../theme/palette.scss';

.ProfileForm {
  @mixin container {
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__top-container {
    @include container;
    padding-top: 58px;
    padding-bottom: 38px;
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  &__name {
    font-size: 18px;
    font-weight: 700;
    color: $darkGrey;
    margin: 0;
  }

  &__status {
    font-size: 16px;
    font-weight: 400;
    color: $mediumGrey;
  }

  &__basic-container {
    @include container;
    padding: 31px 13px;
  }

  &__buttons_container {
    display: flex;
    flex-direction: row;
  }

  &__save_button {
    background-color: $primary;
    color: $white;
  }

  &__emailAgreementContainer {
    align-self: flex-start;

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__checkbox {
        cursor: pointer;
        margin-top: 2px;
      }

      &__container {
        font-size: 13px;
        font-weight: 400;
        color: #9ca3af;
      }
    }
  }
}
