@import '../../../../../theme/palette.scss';

.Quality {
  border-radius: 5px;
  background-color: #f1f1f9;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  white-space: nowrap;
  cursor: pointer;
  &.selected {
    background-color: $primary;
    > .Quality__label {
      color: $white;
    }
  }
  &__label {
    color: $darkGrey;
    font-size: 13px;
    font-weight: 400;
  }
}
