@import '../../../theme/mixins.scss';
@import '../../../theme/palette.scss';
@import '../../../theme/global-variables.scss';

.LocationForm {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__map {
    height: 300px;
    width: 100%;

    @media (max-width: $minDesktopWidth) {
      height: 180px;
    }
  }

  &__buttonsContainer {
    display: flex;
    justify-content: center;
  }

  &__save {
    @include primaryButton;
    height: 56px;
    width: 92px;
    box-shadow: none;
  }

  &__delete {
    @include secondaryButton;
    color: $lightRed;
    border-color: $lightRed;
    height: 56px;
    width: 92px;
    box-shadow: none;
  }

  &__additionalBlock {
    &__header {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
    }

    &__title {
      font-weight: 700;
      font-size: 18px;
      color: #323246;
      margin-inline-end: 6px;
    }

    &__icon {
      cursor: pointer;
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;

    &__label {
      font-weight: 400;
      font-size: 16px;
      color: #323246;
    }
  }

  &__toggle:not(:last-child) {
    margin-bottom: 15px;
  }

  &__infoModal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-weight: 700;
      font-size: 25px;
      color: #323246;
      margin-top: 20px;
    }

    &__content {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: $mediumGrey;
      margin-top: 20px;
      margin-bottom: 25px;
    }

    &__button {
      @include primaryButton;
    }
  }
}

.LocationAddForm {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.LocationEditForm {
  display: flex;
  flex-direction: column;
  flex: 1;
}
