@import '../../../../theme/global-variables.scss';
@import '../../../../theme/palette.scss';

.PaymentDetails {
  &__title-container {
    display: flex;
    margin-bottom: 14px;

    & span {
      color: #323246;
      font-weight: 700;
      font-size: 16px;
      margin-inline-start: 5px;
    }
  }

  &__paymentItem {
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 18px 25px 22px;
    margin-bottom: 25px;

    &__valueContainer {
      display: flex;
      justify-content: space-between;

      &__key {
        color: #9e9eb8;
        font-weight: 400;
        font-size: 16px;
      }

      &__value {
        color: #323246;
        font-weight: 400;
        font-size: 16px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(203, 203, 226, 0.5);
        padding-bottom: 16px;
      }

      &:not(:first-child) {
        padding-top: 14px;
      }
    }
  }

  &__invoices {
    margin-bottom: 140px;
  }

  &__invoice {
    cursor: pointer;
    background: #ffffff;
    border-radius: 10px;
    padding: 17px 8px;
    margin-bottom: 16px;
    width: 100%;

    &__content {
      display: flex;
      align-items: center;

      &__left {
        margin-inline-end: 12px;
      }

      &__right {
        width: 100%;
      }

      &__detailsItem {
        color: #9ca3af;
        font-weight: 400;
        font-size: 12px;
      }

      &__detailsItem:not(:last-child) {
        margin-inline-end: 4px;
      }

      &__titles {
        display: flex;
        justify-content: space-between;
      }

      &__title {
        color: #323246;
        font-weight: 600;
        font-size: 14px;
      }

      &__paymentStatus {
        &--unpaid {
          color: #fcb35c;
        }

        &--processing {
          color: #7094f7;
        }

        &--paid {
          color: #5eaa6f;
        }
      }
    }
  }

  &__alert {
    padding: 24px 21px 19px 13px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 30px;

    &--primary {
      padding: 13px 21px 13px 13px;
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
      color: #4e5d78;
      margin-inline-start: 9px;
    }

    &__content {
      margin-top: 8px;
      display: flex;

      &__texts {
        display: flex;
        flex-direction: column;
        max-width: 400px;
      }

      &__title {
        font-weight: 400;
        font-size: 13px;
        color: #fcb35c;
      }

      &__title-primary {
        font-size: 16px;
        font-weight: 600;
        color: #4e5d78;
      }

      &__subtitle {
        margin-top: 5px;
        font-weight: 400;
        font-size: 13px;
        color: $mediumGrey;
      }

      &__subtitle-primary {
        margin-top: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #4e5d78;
      }
    }
  }

  &__cancelButtonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__cancelButton {
    font-weight: 400;
    font-size: 16px;
    color: #ea6f7e;
    cursor: pointer;
  }
}

.PayButton {
  &__text {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;

    @media (max-width: 340px) {
      margin-bottom: 10px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    &__title {
      color: #9ca3af;
      font-weight: 400;
      font-size: 14px;
    }

    &__amount {
      color: #4e5d78;
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__button {
    padding: 9.5px 50.5px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    background: #7767e4;
    border-radius: 5px;
    outline: none;
  }
}
