@import '../../../../theme/palette.scss';

.InviteReferralLink {
  position: relative;
  width: 100%;
  cursor: pointer;

  &__leftImg,
  &__rightImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__leftImg {
    left: 5px;
  }

  &__rightImg {
    right: 5px;
  }

  &__input {
    width: 100%;
    color: $mediumGrey;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 8px 16px 32px;
    border: 1px solid #cbcbe2;
    border-radius: 8px;

    &:disabled {
      cursor: pointer;
    }
  }
}
