@import '../../../theme/palette.scss';

.SavedPaymentMethods {
  &__title {
    color: #323246;
    font-weight: 400;
    font-size: 16px;
  }

  &__methodsList {
    margin-top: 24px;
    margin-bottom: 150px;
  }

  &__leanMethod {
    padding: 0 !important;
    padding-top: 24px !important;
  }

  &__method {
    background: #ffffff;
    box-shadow: 0 4px 60px rgba(4, 6, 15, 0.05);
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__container {
      width: 100%;
    }

    &__actions {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      &__button {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #7f72d9;
        color: #7f72d9;
        background-color: unset;
        padding: 10px 45px 10px 45px;

        &--added {
          background: #f1f1f9;
          border-radius: 5px;
          padding: 10px 45px 10px 45px;
          color: $mediumGrey;
          font-weight: 400;
          font-size: 14px;
          margin-inline-end: 10px;
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
    }

    &__logoContainer {
      min-width: 60px;
    }

    &__mainInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__body {
      padding: 0 24px 0 24px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      padding: 10px 24px 10px 24px;
      background: #e7e7f5;
      border-radius: 0 0 16px 16px;
      margin-top: 15px;

      &__left {
        display: flex;
        align-items: center;
      }
    }

    &__leanInfo {
      font-weight: 400;
      font-size: 13px;
      color: #323246;
      margin-inline-end: 8px;

      &__link {
        font-weight: 400;
        font-size: 13px;
        color: #6051bb;
      }
    }

    &__remove {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__remove-title {
      font-weight: 400;
      font-size: 16px;
      color: #eb001b;
      margin-inline-start: 10px;
    }
  }

  &__methodRadio {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: 2px solid #7767e4;
    display: flex;
    justify-content: center;
    align-items: center;

    &__filler {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #7767e4;
    }
  }

  &__continue {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
  }

  &__emptyList {
    color: $grey;
    margin-top: 150px;
    text-align: center;
  }
}

.PayButton {
  &__button {
    padding: 11px 80px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    background: #7767e4;
    border-radius: 5px;
    outline: none;
  }
}

.hidden {
  display: none;
}
