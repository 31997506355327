@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/palette.scss';

.CherryPickingConfirmModal {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 50px;

  &__modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 140%;
    text-align: center;
    color: $darkGrey;
  }

  &__modalText {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #c4c4c4;
    width: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__modalBoldText {
    font-weight: bold;
    color: #76768d;
  }

  &__actionButtonsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__confirm {
    @include primaryButton;
    height: 56px;
    box-shadow: none;
    margin-bottom: 15px;
  }

  &__cancel {
    @include secondaryButton;
    height: 56px;
    box-shadow: none;
  }
}
