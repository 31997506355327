@import '../../../../../theme/palette.scss';

.AffiliateDashboardContainer {
  &__card {
    padding: 16px;
    border-radius: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--align-left {
      align-items: flex-start;
    }
  }

  &__referralList {
    width: 100%;
    align-self: flex-start;
  }

  &__inviteMore {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }

  &__stats {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    cursor: pointer;

    &__margin {
      color: #9e9eb8;
      font-size: 13px;
      font-weight: 400;

      &__percents {
        color: #9e9eb8;
        font-size: 13px;
        font-weight: 600;
      }
    }

    &__sum {
      color: #323246;
      font-size: 25px;
      font-weight: 700;
    }

    &__period {
      color: $mediumGrey;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &__showMore {
    color: #7767e4;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
  }
}
