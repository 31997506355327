@import '../../theme/palette.scss';

.JobDraftCategory {
  flex: 100;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  &__title {
    font-weight: 700;
    font-size: 18px;
    color: $darkGrey;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16.34px;
    color: $lightGrey;
  }
  &__list {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
    overflow-y: scroll;
    padding: 10px 0;
    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 23px 12px 16px;
      cursor: pointer;
      &__left {
        display: flex;
        align-items: center;
      }
      &__title {
        font-size: 16px;
        color: $darkGrey;
      }
      &__image {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      &__img {
        width: 5px;
        height: 10px;
      }
    }
  }
  &__button {
    background-color: $primary;
    color: $white;
  }
}
