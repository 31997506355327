@import '../../../../../theme/palette.scss';

.SeniorityLevel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  &__meterContainer {
    display: flex;
    flex-direction: column;
    margin-inline-end: 28px;
    margin-bottom: 5px;
  }
  &__meter {
    display: flex;
  }
  &__meterStep {
    width: 44px;
    height: 4px;
    border-radius: 2.5px;
    background-color: #e2e2ea;
    margin-inline-end: 2px;
    &--active {
      background-color: $primary;
    }
  }
  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #b5b5be;
    margin-bottom: 11px;
  }
  &__levelName {
    color: $primary;
    text-transform: capitalize;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 0;
  }
}
