@import '../../../../../theme/palette';

.ChooseJobs {
  &__confirmButton {
    background-color: $primary;
    color: $white;
    margin-top: 32px;
  }

  &__titlesContainer {
    display: flex;
    flex-direction: column;

    &__mainTitle {
      font-weight: 700;
      font-size: 18px;
      color: #323246;
      margin-bottom: 8px;
    }

    &__secondaryTitle {
      font-weight: 400;
      font-size: 13px;
      color: #9e9eb8;
      margin-bottom: 24px;
    }
  }

  &__container {
    padding-top: 34px;
  }

  &__jobContainer {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &--selected,
    &:hover {
      border: 1px solid #7767e4;
      padding: 23px;
    }

    &--selected &__actionSelect {
      background: #7767e4;
      border: 1px solid #7767e4;

      &__checkMark {
        display: block;
      }
    }

    &__actionSelect {
      width: 18px;
      height: 18px;
      border: 1px solid #979797;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__checkMark {
        display: none;
      }
    }

    &__actions,
    &__property {
      display: flex;
      justify-content: space-between;
    }

    &__actions {
      margin-bottom: 24px;
      align-items: center;
    }

    &__jobTitle {
      font-weight: 700;
      font-size: 16px;
      color: #323246;
    }

    &__property {
      &:not(:nth-child(2)) {
        padding-top: 16px;
      }
      &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(203, 203, 226, 0.5);
      }

      &__key {
        font-weight: 400;
        font-size: 16px;
        color: #9e9eb8;
      }

      &__value {
        font-weight: 400;
        font-size: 16px;
        color: #323246;
      }
    }
  }
}
