@import '../../../../../../../../../../../../theme/palette.scss';

.CancelShiftModal {
  padding: 0 23px;

  &__title {
    font-family: Roboto Slab;
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
  }

  &__subtitle {
    width: 80%;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $lighterGrey;

    &-highlighted {
      font-weight: 700;
      color: $lightGrey;
    }
  }

  &__checkbox-description {
    font-size: 16px;
    font-weight: 400;
    color: $lightGrey;
  }

  &__confirm-button {
    color: $white;
    background-color: $pink;
    border-color: $pink;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__form {
    width: 100%;
  }

  &__textarea-container {
    .TextInput__input {
      min-height: 75px;
    }
  }
}
