@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.JobDraftBoarding {
  display: flex;
  flex-direction: column;

  &__innerContainer {
    margin-top: 63.5px;
    padding: 0 19px;
    display: flex;
    flex-direction: column;

    &__button {
      width: 100% !important;
      height: 56px !important;
      border-color: $grey !important;
      color: $darkGrey;

      &.active {
        background-color: $primary;
        color: $white;
      }
      &.error {
        border-color: $error !important;
        color: $error;
      }
    }
  }
}
