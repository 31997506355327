.HeaderJobsCounter {
  &__container {
    position: relative;
    cursor: pointer;
  }

  &__circle {
    position: absolute;
    top: -6px;
    right: -4px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #ea6f7e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
  }
}

.disabled {
  cursor: not-allowed;
}
