.HomeOnboarding {
  &__plantImg {
    width: 64px;
    height: 64px;
  }
  &__smileImg {
    width: 41px;
    height: 41px;
  }
  &__thumbUpImg {
    width: 64px;
    height: 64px;
  }
  &__arrowDownImg {
    width: 64px;
    height: 64px;
    align-self: flex-end;
  }
  &__bigImg {
    height: 60%;
  }
}
