@import '../../../../../theme/palette.scss';

.DuplicateJob {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
      font-size: 18px;
      font-weight: 700;
      color: $darkGrey;
    }

    &__subtitle {
      font-size: 13px;
      font-weight: 400;
      color: $lightGrey;
    }
  }

  &__button {
    background-color: $primary;
    border: 1px solid $primary !important;
    color: $white;
  }
}
