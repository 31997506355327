@import '../../../../../theme/palette';

.JobDraftInterview {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding: 0 1px;

  &__searchContainer {
    margin-top: -40px;
  }

  &__list {
    flex: 1;
    border: 1px solid $grey;
    border-radius: 10px;
    padding-top: 9px;
    padding-bottom: 2px;
    padding-inline-start: 16px;
    padding-inline-end: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.error {
      border: 1px solid $error;
    }

    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 11px 0;
      cursor: pointer;
      padding-bottom: 8px;
      border-bottom: 1px solid $grey;

      &__label {
        font-size: 16px;
        color: $darkGrey;
        font-weight: 400;
      }
    }
  }

  &__addQuestionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    color: $primary;
    &.Button {
      width: unset;
    }
  }

  &__plusImg {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
}
