@import '../../theme/palette.scss';
@import '../../theme/mixins.scss';

.OrderExtension {
  &__popup {
    display: flex;
    flex-direction: column;
    &__innerContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 0 19px;
      &__button {
        width: 100% !important;
        height: 56px !important;
        border-color: $grey !important;
        color: $darkGrey;
        &__content {
          display: flex;
          flex-direction: column;
        }
        &.active {
          background-color: $primary;
          color: $white;
        }
        &.active &__content__description {
          color: $white;
        }
        &:not(.active) &__content__description {
          color: rgba(110, 110, 135, 1);
        }
      }
      &__hint {
        font-size: 13px;
        color: $orange;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  &__job {
    display: flex;
    width: 100%;
    padding: 10px 0;
    & > *:not(:last-child) {
      margin-inline-end: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(203, 203, 226, 0.5);
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin-inline-end: 55px;
      min-width: 175px;
      width: 175px;
    }
    &__location {
      color: #9e9eb8;
      font-size: 13px;
      font-weight: 400;
    }
    &__designation {
      color: #323246;
      font-size: 16px;
      font-weight: 600;
    }
    &__quantity {
      color: $mediumGrey;
      font-size: 16px;
      font-weight: 400;
    }
    &__extensionDate {
      color: #7094f7;
      font-size: 14px;
      font-weight: 400;
    }
    &__selector {
      display: flex;
      border-radius: 8px;
      border: 1px solid #cbcbe2;
      min-width: 140px;
      width: 280px;
      padding: 0 10px;
      height: 64px;
      justify-content: space-between;
      align-items: center;
      & img {
        width: 24px;
        height: 45px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__title {
          color: #9e9eb8;
          font-size: 13px;
          font-weight: 400;
          &--no-value {
            color: $mediumGrey;
            font-size: 16px;
            font-weight: 600;
          }
        }
        &__value {
          color: #323246;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
