@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/global-variables';

.RatingRequestsList {
  &__cardContainer {
    width: 100%;
    margin: 0;
  }
  &__emptyList {
    @include emptyState;
  }

  &__infoPanel {
    background: #fcb35c33;
    border-radius: 10px;
    width: 100%;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;

    &__title {
      color: #323246;
      margin-bottom: 11px;
      font-size: 25px;
      font-weight: 700;
      line-height: 33px;
    }

    &__subtitle {
      color: #6e6e87;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &__latest {
    margin-bottom: 150px;

    &__card {
      background-color: #fff;
      border-radius: 10px;
      padding: 17px 16.5px;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      &__title {
        color: #323246;
        font-size: 20px;
        font-weight: 400;
      }

      &__subtitle {
        flex-direction: row;
        align-items: center;
        display: flex;
        margin-top: 3px;

        &__text {
          color: #9e9eb8;
          font-size: 14px;
          font-weight: 400;
          margin-left: 11px;
        }
      }

      &__sp {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding: 18px 0;

        @media (max-width: $minDesktopWidth) {
          flex-direction: column;
          align-items: flex-start;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #cbcbe280;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &__left {
          white-space: nowrap;
          flex-direction: row;
          flex: 1;
          align-items: center;
          min-width: 0;
          display: flex;
          cursor: pointer;

          @media (max-width: $minDesktopWidth) {
            margin-bottom: 10px;
          }
        }

        &__img {
          width: 54px;
          height: 54px;
          border-radius: 100%;
        }

        &__info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &__title {
            color: #323246;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: 400;
            overflow: hidden;
          }

          &__subtitle {
            color: #9e9eb8;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
