@import '../../theme/global-variables.scss';

.Modal {
  position: absolute;
  left: 50vw;
  top: 10vh;
  max-height: 90vh;
  overflow: auto;
  transform: translate(-50%, 0);
  width: 90vw;
  background: white;
  border-radius: 5px;
  padding: 20px;

  @media (min-width: $minDesktopWidth) {
    width: 500px;
    padding: 40px 20px 20px 20px;
  }

  &__close {
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 20px;
    cursor: pointer;
  }
}
