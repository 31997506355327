@import '../../../../../../theme/palette.scss';

.Section {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 25px 18px 25px 22px;
  border-radius: 10px;
  overflow: hidden;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 17px;
    border-bottom: 1px solid $grey;
    cursor: pointer;

    &__title {
      font-size: 16px;
      color: $darkGrey;
      font-weight: 600;
    }

    &__arrowImg {
      width: 10px;
      height: 5px;
      transition: transform 0.5s;
      user-select: none;

      &.turned {
        transform: rotate(-90deg);
      }
    }
  }

  &__statsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    &__stat {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        font-size: 12px;
        color: #b5b5be;
        font-weight: 400;
      }

      &__value {
        font-size: 16px;
        color: $mediumGrey;
        font-weight: 600;
      }
    }
  }

  &__ogrammers {
    transition: max-height 0.4s ease-out;

    &.collapsed {
      transition:
        max-height 0.4s ease-out,
        opacity 0.1s 0.3s;
      max-height: 0 !important;
      opacity: 0;
    }
  }
}
