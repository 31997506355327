@import '../../../../theme/palette.scss';

.ExperienceLevel {
  &__innerContainer {
    display: flex;
    flex-direction: column;
    &__button {
      width: 90%;
      border: 1px solid $grey;
      background-color: $white;
      color: $darkGrey;
      &.active {
        border: 1px solid $primary;
        background-color: $primary;
        color: $white;
      }
    }
    &__description {
      font-size: 13px;
      color: $lightGrey;
      text-align: center;
    }
  }
}
