@import '../../../../theme/palette.scss';

.InterviewSection {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;
  }
  &__chatImg {
    width: 16px;
  }
  &__title {
    font-family: Source Sans Pro;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $darkGrey;
    letter-spacing: -0.16px;
  }
  &__listContainer {
    margin-top: 16px;
  }
  &__listItem {
    min-width: 268px;
  }
}
