@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/palette.scss';

.CherryPickingSuccessModal {
  display: flex;
  padding: 42px 33px 33px;
  padding-top: 37px;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 33px;

  &__modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 140%;
    text-align: center;
    color: $darkGrey;
    margin-top: 21px;
    margin-bottom: 16px;
  }

  &__modalText {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #c4c4c4;
  }

  &__modalBoldText {
    font-weight: bold;
    color: #76768d;
  }

  &__actionButtonsContainer {
    display: flex;
    flex-direction: column;
  }

  &__done {
    @include primaryButton;
    height: 56px;
    box-shadow: none;
    width: 100%;
  }
}
