@import '../../../../theme/palette.scss';

.Gender {
  &__innerContainer {
    display: flex;
    flex-direction: column;
    &__button {
      width: 90%;
      border: 1px solid $grey;
      background-color: $white;
      color: $darkGrey;
      &.active {
        border: 1px solid $primary;
        background-color: $primary;
        color: $white;
      }
    }
  }
}
