@import '../../../../../../theme/palette.scss';
@import '../../../../../../theme/mixins.scss';

.CalendarWeek {
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  position: sticky;
  top: -1;
  background: #fff;
  z-index: 2;
  &__emptyColumn {
    width: 20%;
    min-width: 248px;
  }
  &__separator {
    height: 100%;
    width: 1px;
    background-color: #f3f3f3;
  }
  &__column {
    width: 11.4%;
    min-width: 143px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #f3f3f3;
    &.active {
      border-bottom-color: $blue;
    }
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 5px;
      padding-inline-start: 11px;
      padding-inline-end: 13.2px;
      border-radius: 15px;
      &__day {
        font-family: Source Sans Pro;
        font-size: 16px;
        color: #4e5d78;
        font-weight: 600;
        line-height: 20px;
      }
      &__weekday {
        font-family: Source Sans Pro;
        font-size: 16px;
        color: #b0b7c3;
        font-weight: 600;
        line-height: 20px;
      }
      span.active {
        color: $blue;
      }
    }
  }
}
