@import '../../../theme/global-variables.scss';

.StickPayButton {
  &__text {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;

    @media (max-width: 340px) {
      margin-bottom: 10px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    &__title {
      color: #9ca3af;
      font-weight: 400;
      font-size: 14px;
    }

    &__amount {
      color: #4e5d78;
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__button {
    padding: 11px 80px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    background: #7767e4;
    border-radius: 5px;
    outline: none;

    @media (max-width: $minDesktopWidth) {
      padding: 11px 50px;
    }
  }
}
