@import '../../../../../theme/palette';

.JobDraftDescription {
  display: flex;
  flex-direction: column;
  &__textArea {
    min-height: 150px;
    width: 100%;
    border: 1px solid $grey;
    border-radius: 5px;
    padding: 10px 5px 10px 11px;
    outline: none;
    &.error {
      color: $error;
      border: 1px solid $error;
    }
  }
}
