@import '../../../theme/palette.scss';

.Select {
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  background-image: url('./assets/arrow-dropdown.svg');
  background-repeat: no-repeat;
  background-position: 98.5% 50%;

  &__input {
    width: 100%;
    height: 56px;
    border-radius: 5px;
    padding-inline-start: 15px;
    padding-top: 20px;
    border: 1px solid #cbcbe2;
    background-color: transparent;
    color: $darkGrey;
    font-size: 16px;
    font-weight: 400;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    cursor: pointer;

    &:focus {
      border: 1px solid $primary;
    }
  }

  &__label {
    position: absolute;
    left: 16px;
    top: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #9e9eb8;
  }

  &__icon-container {
    position: absolute;
    right: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__icon {
      width: 18.42px;
      height: 20px;
    }
  }
}

.CustomSelect {
  & input {
    box-shadow: none !important;
  }

  &__control {
    outline: none;
    height: 56px;
    box-sizing: border-box;
    border: 1px solid #cbcbe2;
    box-shadow: none;

    &--focused,
    &:hover {
      border: 2px solid #9193e2 !important;
      box-shadow: 0 0 3px #9193e2;
    }
  }

  &__menu {
    z-index: 2 !important;
  }

  &__placeholder,
  &__singleValue {
    font-size: 16px;
    font-weight: 400;
    margin-inline-start: 6px !important;
  }
}
