@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.StaffAmount {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__input {
      overflow: hidden;

      .Input__input {
        text-align: center;
      }

      .Input__labelContainer.fullContainer {
        inset-inline-start: 0px;

        .Input__labelContainer__label {
          inset-inline-start: unset;
          position: unset;
        }
      }

      &_error {
        .Input__input {
          border-color: $error;
        }
        .Input__labelContainer {
          &__label {
            color: $error;
          }
        }
      }
    }

    &__button {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      border: 1px solid $lighterGrey;
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30%;
      &_error {
        border-color: $error;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
