@import '../../../../../theme/palette.scss';

.Dropdown__main-avatar {
  width: 30;
  height: 30;
  border-radius: 50%;
}

.Dropdown__link {
  color: $darkGrey;
  font-size: 16px;
  font-weight: normal;
  margin: 0;

  &:hover {
    background-color: $veryLightGrey;
    color: #1890ff;
  }
}
