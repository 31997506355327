@import '../../../theme/palette.scss';

.InviteUser {
  &__container {
    background-color: $white;
    padding-top: 27px;
    padding-bottom: 20px;
    padding-inline-start: 13px;
    padding-inline-end: 13px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    &__form {
      width: 100%;
      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &__button {
          width: 35%;
          display: flex;
          justify-content: center;
          align-items: center;
          &.submit {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }
}
