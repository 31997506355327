@import '../../theme/palette.scss';

.DateInput {
  display: flex;
  width: 100%;
  height: 56px;
  position: relative;

  &__Calendar {
    position: absolute;
    top: calc(100% + 5px);
    inset-inline-start: 0;
    background: #fff;
    z-index: 351;
    width: 400px !important;
    overflow: hidden !important;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    @media (max-width: 475px) {
      width: 100% !important;
    }
  }

  &__input {
    width: 100%;
    border-radius: 5px;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-top: 20px;
    border: 1px solid #cbcbe2;
    color: $darkGrey;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    cursor: pointer;

    &:focus {
      border: 1px solid $primary;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      inset-inline-start: 0;
      position: absolute;
      inset-inline-end: 0;
      top: 0;
      width: auto;
      display: hidden;
    }
  }

  &__labelContainer {
    position: absolute;
    inset-inline-start: 16px;
    top: 8px;
    z-index: 1;

    &__label {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: hsl(0, 0%, 50%);
    }

    &.fullContainer {
      top: 0px;
      inset-inline-start: 0px;
      padding-inline-start: 16px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: $white;
      border: 1px solid #cbcbe2;
      cursor: pointer;
      color: hsl(0, 0%, 50%);
      font-size: 14px;
    }
  }

  &__icon-container {
    position: absolute;
    inset-inline-end: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    cursor: pointer;

    &__icon {
      width: 20px;
      height: 20px;
    }
  }
}
