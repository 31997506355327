@import '../../../../../theme/palette.scss';

.EducationCard {
  display: flex;
  flex-direction: row;
  &__imgContainer {
    min-width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5fb;
    border-radius: 6px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title {
      font-size: 16px;
      font-weight: 600;
      color: $darkGrey;
    }
    &__degreeContainer {
      &__degree {
        font-size: 16px;
        color: $mediumGrey;
      }
      &__text {
        font-size: 16px;
        color: $lightGrey;
      }
      &__field {
        font-size: 16px;
        color: $mediumGrey;
      }
    }
  }
}
