@import '../../../../../theme/palette';

.JobDraftUniform {
  display: flex;
  flex-direction: column;

  &__uniformDescription {
    font-size: 13px;
    color: $lightGrey;
    font-weight: 400;
  }

  &__uniformSelect {
    background-color: $white;
    &.error {
      .Select__input {
        color: $error;
        border: 1px solid $error;
      }
    }
  }
}
