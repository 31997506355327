.SpTime {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  &__top {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
