@import '../../../../../../../theme/palette.scss';
@import '../../../../../../../theme/mixins.scss';

.SpCanceled {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  &__top {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    &__timeContainer {
      cursor: not-allowed;
      flex: 1;
      border: 1px solid #cbcbe2;
      height: 56px;
      border-radius: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-inline-start: 8px;
      background-color: #f5f5fb;
      &__label {
        font-family:
          Source Sans Pro,
          sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #9e9eb8;
      }
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__imgContainer {
      padding: 10px;
      cursor: pointer;
    }
    &__img {
      width: 16px;
      height: 4px;
    }
  }
}
