@import '../../../../theme/palette.scss';

.WorkCategories {
  flex: 100;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  &__list {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 11px 12px 15px;
      &__title {
        font-size: 16px;
        color: $darkGrey;
      }
    }
  }
}
