@import '../../../../../theme/palette.scss';
@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/global-variables.scss';

.ApplicantCard {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 14px 17px 21px;
  width: 100%;
  &--selected {
    border-color: $green;
    transition: 0.25s all;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__avatarAndNameContainer {
    display: flex;
    align-items: flex-start;
  }
  &__link {
    margin-top: 0;
    position: relative;
    margin-inline-end: 12px;
    cursor: pointer;
  }
  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e5e5ed;
    object-fit: cover;
    object-position: center;
  }
  &__beacon {
    position: absolute;
    width: 16;
    height: 16;
    bottom: 0;
    inset-inline-end: 0;
  }
  &__nameAndRatingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__nameContainer {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  &__name {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-inline-end: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  &__tag {
    border-radius: 5px;
    background: #02ae8d;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
  }
  &__infoColumn {
    display: flex;
    flex-direction: column;
  }
  &__infoName {
    color: #b5b5be;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &__infoValue {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #44444f;
  }
  &__languageCount {
    color: $lightGrey;
  }
  &__favourite {
    display: flex;
    flex-direction: column;
  }
  &__favouriteIcon {
    @include transparentBackground;
    align-self: flex-end;
    display: flex;
    align-items: flex-start;
    margin-inline-end: 3px;
    height: 20px;
    margin-top: 10px;
  }
  &__applicantInterview {
    margin: 12px 0;
    @media screen and (min-width: $minDesktopWidth) {
      margin-top: 16px;
    }
  }

  &__skillMatch {
    margin-bottom: 12px;
  }
}
