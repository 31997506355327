@import '../../../../../theme/palette.scss';

.UserCard {
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 18px;
  padding-inline-start: 13px;
  padding-inline-end: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &.increased {
    padding-bottom: 28px;
  }
  &.clickable {
    cursor: pointer;
  }
  &__leftContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &__userPhoto {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-top: 4px;
      &.placeholder {
        width: 15px;
        height: 17px;
      }
    }
    &__userInfo {
      overflow: visible;
      &__name {
        font-size: 16px;
        font-weight: 400;
        color: $darkGrey;
      }
      &__locations {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        &__location {
          font-size: 14px;
          font-weight: 400;
          color: $lightGrey;
          margin-inline-end: 10px;
        }
      }
    }
    &__roleContainer {
      height: 20px;
      padding-inline-start: 8px;
      padding-inline-end: 8px;
      border-radius: 5px;
      font-weight: 400;
      font-size: 13px;
      margin-top: 2px;
      &.admin,
      &.owner {
        background-color: rgba($color: #8ecd9c, $alpha: 0.2);
        color: $lightGreen;
      }
      &.manager {
        background-color: rgba($color: $orange, $alpha: 0.2);
        color: $orange;
      }
    }
  }
  &__editImg {
    width: 20px;
    height: 20px;
  }
}
