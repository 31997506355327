@import '../../theme/palette.scss';
@import '../../theme/mixins.scss';

.TooManyAttempts {
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    flex: 1;
    margin-top: 14px;
    margin-bottom: 26px;
    border-radius: 10px;
    padding-top: 61px;
    padding-bottom: 24px;
    width: calc(100% - 32px);
  }
  &__modalTitle {
    @include title;
    font-style: normal;
    font-size: 25px;
    line-height: 33px;
    margin-top: 40px;
    margin-bottom: 16px;
    text-align: center;
    color: $darkGrey;
  }
  img {
    margin-top: 10px;
    width: 218px;
  }
  p {
    text-align: center;
    color: $lighterGrey;
    margin-bottom: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    width: 300px;
  }
  &__backButton {
    @include primaryButton;
    height: 48px;
    box-shadow: none;
    width: 168px;
    margin-top: auto;
  }
}
