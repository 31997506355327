.SelectInput {
  position: relative;
  height: 60px;
  .SelectInput__inputText {
    position: absolute;
    width: 100%;
    margin-top: -3px;
  }
  .SelectInput__options {
    opacity: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    width: 12px;
  }
}
