@import '../../../theme/palette.scss';
@import '../theme/mixins.scss';

.VerifyEmail {
  @include auth__container;
  &__logo {
    @include auth__logo;
  }
  &__title {
    @include auth__title;
  }
  &__subtitle {
    @include auth__subtitle;
  }
  &__button {
    @include auth__button;
  }
  &__resend-description {
    color: $lightGrey;
    text-align: center;
  }
  &__change-email-link {
    color: $primary;
    font-size: 16px;
  }
}
