@import '../../../../theme/palette.scss';

.OrderCard {
  background-color: $white;
  border-radius: 10px;
  padding: 17px 16.5px;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-size: 16px;
      font-weight: 600;
      color: $darkGrey;
    }
    &__dateContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__calendarImg {
        width: 16px;
        height: 16px;
      }
      &__dateLabel {
        font-size: 16px;
        font-weight: 400;
        color: $lightGrey;
      }
    }
  }
  &__occupationRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__label {
      font-size: 16px;
      font-weight: 400;
      color: $lightGrey;
    }
    &__status {
      border-radius: 5px;
      padding: 0 4px;
      height: 20px;
      min-width: 60px;
      text-align: center;
      &.draft {
        background-color: rgba(145, 147, 226, 0.15);
        color: #9e9eb8;
      }
      &.underReview {
        background-color: rgba(71, 117, 245, 0.1);
        color: #7094f7;
      }
      &.pendingConfirmation {
        background-color: rgba(252, 179, 92, 0.1);
        color: #fcb35c;
      }
      &.upcoming {
        background-color: rgba(119, 103, 228, 0.15);
        color: rgba(119, 103, 228, 1);
      }
      &.started {
        background-color: rgba(61, 213, 152, 0.1);
        color: rgba(61, 213, 152, 1);
      }
      &__label {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
