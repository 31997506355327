@import '../../../../theme/palette.scss';
@import '../../../../theme/global-variables.scss';

.MarketDesktopHeaderBox {
  height: 52px;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 80px;
  }
}

.MarketDesktopHeader {
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__back {
    cursor: pointer;
  }
  &__main-left {
    display: flex;
    align-items: flex-start;
  }
  &__main-right {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 120px;
    height: 26px;
    &.hidden {
      visibility: hidden;
    }
  }
  &__title {
    font-family: Roboto Slab;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: $darkGrey;
    margin: 0;
    text-align: center;
    flex: 1;
  }
  &__subTitle {
    text-align: center;
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  &__main__separator {
    width: 22px;
  }
}
