@import '../../../theme/global-variables';

.filterButton {
  border: 1px solid #cbcbe2;
  border-radius: 8px;
  padding: 0 12px 0 12px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  @media (max-width: $maxMobileWidth) {
    background: #fafbfc;
    border: none;
    margin-inline-end: 0 !important;
  }

  &__title {
    color: #6e6e87;
    font-size: 16px;
    font-weight: 600;
    margin-inline-start: 8px;
  }

  &__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ea6f7e;
    border-radius: 100%;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    margin-inline-start: 8px;
    width: 24px;
    height: 24px;
  }
}
