@import '../../theme/palette.scss';

.DayItem {
  cursor: pointer;
  flex: 1;
  height: 38px;
  border-radius: 5px;
  background-color: #f1f1f9;
  color: $darkGrey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  &.active {
    background-color: $primary;
    color: $white;
  }
}
