.FiltersPopup {
  &__titleContainer {
    padding: 8px;
    border-bottom: 1px solid #cbcbe2;
    display: flex;
    align-items: center;

    & span {
      margin-inline-start: 8px;
      color: #323246;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__filterContainer {
    margin-top: 12px;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cbcbe2;
    border-radius: 8px;
    padding: 0 8px 0 8px;
    height: 56px;

    &:hover {
      box-shadow: 0 0 3px #9193e2;
      border: 2px solid #9193e2 !important;
      cursor: pointer;
    }

    & > span {
      color: hsl(0, 0%, 50%);
    }
  }

  &__text > input {
    font-size: 13px;
  }

  &__actions {
    margin-top: 12px;
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 8px;
    }
  }
}
