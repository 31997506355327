@import '../../../theme/palette.scss';
@import '../../../theme/global-variables.scss';

.toggleImg {
  display: inline-block;
  width: 22px;

  @media (min-width: $minDesktopWidth) {
    display: none;
  }
}

.Sidebar {
  .desktop {
    display: none;

    @media (min-width: $minDesktopWidth) {
      display: unset;
    }
  }

  .mobile {
    @media (min-width: $minDesktopWidth) {
      display: none;
    }
  }

  @media (max-width: $maxMobileWidth) {
    flex-direction: row;
    position: absolute;
    width: 100vw;
    left: -100vw;
    top: 0;
    transition: left 0.5s;
    background-color: none;
  }

  @media (min-width: $minDesktopWidth) {
    width: 220px;
    transition: width 0.3s;
    min-height: 100vh;
    padding-top: 31px;
    background-color: $white;
  }

  overflow-x: hidden;
  overflow-y: scroll;
  /* Hide scrollbar but keep scroll functionality */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.mobile-active {
    @media (max-width: $maxMobileWidth) {
      left: 0;
    }

    @media (min-width: $minDesktopWidth) {
      overflow: scroll;
      height: 100%;
    }
  }

  &.desktop-collapsed {
    @media (min-width: $minDesktopWidth) {
      width: 90px;

      .Submenu__list-header {
        opacity: 0;
      }

      .LinkItem__link {
        padding-inline-start: 34px;

        span {
          opacity: 0;
        }
      }

      .OrderButton {
        span {
          display: none;
        }

        &__separator {
          display: none;
        }
      }
    }
  }

  &__mobile-container {
    /* Hide scrollbar but keep scroll functionality */
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 120px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $maxMobileWidth) {
      height: 100%;
      overflow: scroll;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 70%;
      max-width: 300px;
      height: 100vh;
      background: $white;
      padding: 20px 0 40px 0;
    }
  }

  &__mobile-inner-container {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-inline-start: 25px;
      margin-inline-end: 25px;
    }
  }

  &__mobile-app-logo {
    width: 120px;
    height: 26px;
    margin-inline-start: -6px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__company-logo {
    margin-inline-start: 25px;
    width: 27px;
    height: 27px;
  }

  .LinkItem__link span {
    transition: opacity 0.3s;
  }

  &__desktop-header {
    display: none;

    @media (min-width: $minDesktopWidth) {
      display: flex;
    }

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 25px;
    padding-inline-end: 28.5px;

    &__app-logo {
      width: 120px;
      height: 26px;
      cursor: pointer;
    }

    &__app-logo-small {
      width: 24px;
      height: 20px;
      cursor: pointer;
    }

    &__close-sidebar-img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &__inner-container {
    padding: 0 20px;
  }

  &__overlay-foreground {
    height: 0;
    width: 0;
    background-color: unset;
    transition: background-color 1s;
    z-index: 1;
  }

  &.mobile-active + &__overlay-foreground {
    @media (max-width: $maxMobileWidth) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      min-height: 100vh;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
