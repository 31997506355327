@import '../../../../theme/palette.scss';
@import '../../../../theme/mixins.scss';

.AffiliateReferralListItem {
  align-self: flex-start;
  width: 100%;
  padding: 10px 0 10px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(203, 203, 226, 1);
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    &__left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__image {
        width: 24px;
        height: 24px;
        border-radius: 100%;
      }
      @include ant-image-small-mask;
      &__userInfo {
        margin-inline-start: 5px;
        &__name {
          color: #323246;
          font-size: 16px;
          font-weight: 400;
          margin-inline-end: 8px;
        }
        &__shifts {
          color: #9e9eb8;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
    &__right {
      &__statusLabel {
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(142, 205, 156, 0.2);
        color: #5eaa6f;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }

  &__bottom {
    &__table {
      width: 100%;
      &__headers {
        & td {
          color: #9e9eb8;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      &__content {
        & td {
          color: $mediumGrey;
          font-size: 14px;
          font-weight: 600;
        }
      }
      & tr td:last-child {
        text-align: end;
      }
    }
  }
}
