@import '../../../../../../theme/palette.scss';

.CountStepper {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 33px;
  min-width: 87px;
  &__button {
    cursor: pointer;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__decreaseImg {
      width: 8px;
      height: 2px;
    }
    &__increaseImg {
      width: 8px;
      height: 8px;
    }
  }
  &__value {
    font-size: 16px;
    color: $lightGrey;
    &.active {
      color: $darkGrey;
    }
  }
}
