@import '../../theme/mixins.scss';

.ErrorModal {
  display: flex;
  padding: 42px 33px 33px;
  padding-top: 37px;
  padding-bottom: 33px;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;

  &__title {
    text-align: center;
    font-family: Roboto Slab;
    font-size: 25px;
  }

  &__message {
    text-align: center;
    width: 75%;
    font-size: 16px;
    color: #c0c0c0;
  }

  &__ok {
    @include primaryButton;
    width: 100px;
    height: 37px;
  }
}
