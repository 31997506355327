@import '../../../../theme/palette.scss';

.OrderSummaryWelcome {
  width: 100%;
  background-color: rgba($lightBlue, 0.15);
  border-radius: 10px;
  padding: 25px 15px;
  text-align: center;
  &.orange {
    background-color: $orangeLightest;
    h1 {
      span {
        color: $orange;
      }
    }
  }
  h1 {
    font-weight: bold;
    color: $darkGrey;
    margin-bottom: 11px;
    font-family: Roboto Slab;
    font-size: 25px;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 33px;
    span {
      color: $primary;
    }
  }
  p {
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 0;
  }

  &__completeRegistrationLink {
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-decoration-line: underline;
    color: #fcb35c;
    cursor: pointer;
  }
}
