.StepIndicator {
  &__container {
    margin-bottom: 8px;
    width: 100%;
  }

  &__stepNumber {
    margin-bottom: 8px;

    &__content {
      font-weight: 400;
      font-size: 13px;
      color: #9e9eb8;
    }
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__stepContainer:not(:last-child) {
    position: relative;
    flex: 1;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      height: 2px;
      background-color: #cbcbe2;
      order: -1;
      width: calc(100% - 41px);
      inset-inline-start: 37px;
    }
  }

  &__step {
    width: 32px;
    height: 32px;
    background: #babed6;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &--current {
      background: #6051bb;
    }

    &__content {
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
    }

    &__separator {
      height: 0;
      width: 37%;
      border: 1px solid #cbcbe2;
    }
  }
}
