.AcceptTerms {
  display: flex;
  align-items: center;
  align-self: flex-start;

  & p {
    color: #9e9eb8;
    font-size: 16px;
  }

  & input {
    margin-inline-end: 5px;
  }

  & a {
    color: #7f72d9;
    margin: unset;
    font-size: 16px;
  }
}
