.Button {
  &__primary {
    background: #7767e4;
    border-radius: 5px;
    padding: 12px 35px;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;

    &--disabled {
      opacity: 0.5;
    }
  }
}
