@import '../../../../../theme/palette.scss';
@import '../../../../../theme/global-variables.scss';

.AnswerForm {
  &__question {
    margin-top: 24px;
    margin-bottom: 20px;
  }

  &__questionIndex {
    color: $darkGrey;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__questionText {
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__videoPlayer {
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.85) 100%);
    height: 40vh;
    @media screen and (min-width: $minDesktopWidth) {
      height: 50vh;
    }
  }

  &__ratingContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__ratingLabel {
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.16px;
  }

  &__ratingLoading {
    text-align: end;
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
