@import '../../../../../theme/palette.scss';
@import '../../../../../theme/global-variables.scss';

.MobileHeaderBox {
  height: 32px;
  @media (min-width: $minDesktopWidth) {
    display: none;
  }

  &__container {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    height: 60px;
    background: rgba(245, 245, 251, 0.95);
    z-index: 50;
  }
}

.MobileHeader {
  width: 100%;
  padding: 0 24px 0 20px;
  display: flex;
  justify-content: center;

  @media (min-width: $minDesktopWidth) {
    display: none;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__back {
    cursor: pointer;
  }
  &__titleContainer {
    display: flex;
    align-items: center;
    height: 37px;
    margin: 0 auto;
  }
  &__main-left {
    display: flex;
    align-items: center;
  }
  &__main-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__logo {
    width: 36px;
    height: 30px;
  }
  &__title {
    font-family: Roboto Slab;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $darkGrey;
    margin: 0;
    text-align: center;
    flex: 1;
  }
  &__subTitle {
    text-align: center;
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  &__main__separator {
    width: 22px;
  }

  &__separator {
    @media (min-width: $minDesktopWidth) {
      display: none;
    }
  }
}
