.ReferralsListPage {
  &__card {
    padding: 16px;
    border-radius: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--align-left {
      align-items: flex-start;
    }
  }

  &__referralList {
    width: 100%;
    align-self: flex-start;
  }

  &__statuses {
    width: 100%;
    align-self: flex-start;
    &__item {
      padding: 10px 8px;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(203, 203, 226, 1);
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__left {
        display: flex;
        align-items: center;
        &__circle {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin-inline-end: 20px;
        }
        &__title {
          font-size: 16px;
          font-weight: 400;
        }
      }
      &__right {
        &__title {
          color: #323246;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
