@import '../../../theme/palette.scss';

.TimeInput {
  position: relative;
  &__container {
    & > div {
      display: flex;
      flex-direction: column;
    }
    // Hide standard Firefox marker
    list-style: none;
    // Hide standard Chrome marker
    &::-webkit-details-marker {
      display: none;
    }
    padding: 8px 12px 8px 8px;
    height: 100%;
    border: 1px solid $grey;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__timeInput {
    width: 100% !important; // override inline-style of lib's element
    padding-inline-start: 0;
    border: none;
    &_hidden {
      height: 0px;
      width: 0px !important;
    }
  }
  &__toggleIcon {
    width: 10px;
  }
  &__label {
    color: $lightGrey;
    font-family: Source Sans Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &__value {
    color: $darkGrey;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.16px;
  }
  &__list {
    min-width: 216px;
    width: 188px;
    height: 200px;
    background-color: $white;
    box-shadow:
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      background: transparent;
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border: none;
      box-shadow: none;
      background: rgb(218, 220, 224);
      border-radius: 8px;
      min-height: 40px;
    }

    &__item {
      min-width: 150px;
      padding: 0 15px;
      line-height: 40px;
      color: rgb(60, 64, 67);
      font-size: 14px;

      &:hover,
      &--selected {
        background-color: rgb(241, 243, 244);
      }
    }
  }
  &__listBottom {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
  }
  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__topColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
    width: 100%;
  }
  &__timeLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $lightGrey;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
  &__arrowTopIcon {
    transform: rotate(180deg);
    cursor: pointer;
    user-select: none;
  }
  &__pickerContainer {
    position: relative;
    cursor: pointer;
  }
  &__timeValue {
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &_highlighted {
      color: $darkGrey;
      background-color: $veryLightGrey;
    }
  }
  &__arrowDownContainer {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 100%;
  }
  &__arrowDownIcon {
    width: 10px;
    margin-bottom: 18px;
    user-select: none;
  }
  &__delimitersContainer {
    position: absolute;
    height: 216px;
    padding: 18px 0;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    flex-direction: column;
    cursor: default;
  }
  &__delimiter {
    height: 36px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &_highlighted {
      background-color: #fafafd;
    }
  }
}
