@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.OrderSummary {
  background-color: $background;
  &__container {
    @include fullScreen;
    padding-top: 34px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  &__terms {
    display: flex;
    align-items: center;
    input {
      margin-top: 11px;
      margin-inline-end: 10px;
    }
    p {
      margin-bottom: 0;
      color: $lightGrey;
      font-size: 16px;
      a {
        color: $primary;
        font-size: 16px;
      }
    }
  }
  &__confirmButton {
    @include primaryButton;
    width: 263px;
    height: 56px;
    margin: 0 auto;
    display: block;
    margin-bottom: 39px;
    transition: 0.25s all;
    &.disabled {
      border: none;
    }
  }
  &__amendButton {
    border: none !important;
    width: unset !important;
    font-size: 16px;
    font-weight: 400;
  }

  &__PayButton {
    &__payBlock {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__termsBlock {
      margin-bottom: 10px;
    }

    &__text {
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;

      @media (max-width: 340px) {
        margin-bottom: 10px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;

      &__title {
        color: #9ca3af;
        font-weight: 400;
        font-size: 14px;
      }

      &__amount {
        color: #4e5d78;
        font-weight: 600;
        font-size: 16px;
      }
    }

    &__button {
      padding: 9.5px 50.5px;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      background: #7767e4;
      border-radius: 5px;
      outline: none;
    }
  }
}
