@import '../../../../../theme/palette';

.JobDraftDates {
  display: flex;
  flex-direction: column;
  &__innerContainer {
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    &__jobToggleRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__label {
        font-size: 16px;
        font-weight: 400;
        color: $darkGrey;
      }
    }
  }
  &__itemWrapper {
    display: flex;
    flex-direction: column;
  }
  &__dateInput {
    &.error {
      .fullContainer {
        border: 1px solid $error !important;
        border-color: $error !important;
      }
    }
  }
}
