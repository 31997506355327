@import '../../../../../../theme/palette.scss';

.MenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__title {
    font-size: 16px;
    font-weight: 400;
    color: $darkGrey;
  }
}
