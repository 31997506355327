@import '../../../../../theme/palette.scss';
@import '../../../../../theme/global-variables.scss';

.ApplicantInterview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 8px;
  padding-inline-end: 16px;
  border-radius: 5px;
  background-color: rgba(211, 213, 218, 0.2);
  cursor: pointer;
  @media screen and (min-width: $minDesktopWidth) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-inline-start: 8px;
    padding-inline-end: 16px;
  }

  &_top {
    background-color: $greenLightest;
  }

  &_middle {
    background-color: $orangeLight;
  }

  &_low {
    background-color: $lightestRed;
  }

  &__side {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 20px;
    color: $lightGrey;
    @media screen and (min-width: $minDesktopWidth) {
      font-size: 15px;
    }
  }

  &__rating {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 20px;
    @media screen and (min-width: $minDesktopWidth) {
      font-size: 15px;
    }

    &_top {
      color: $lightGreen;
    }

    &_middle {
      color: $orange;
    }

    &_low {
      color: $lightRed;
    }
  }

  &__playImg {
    width: 20px;
  }

  &__playLabel {
    color: $blue;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    @media screen and (min-width: $minDesktopWidth) {
      font-size: 15px;
    }
  }
}
