@import '../../../../../../../theme/palette.scss';
@import '../../../../../../../theme/mixins.scss';

.ScheduleItem {
  // position: relative;
  &:last-child {
    padding-bottom: 16px;
  }
  &__row {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    &.odd {
      background-color: #fafbfc;
    }
  }
  .Input {
    height: unset;
    &__input {
      border-width: 0;
      padding: 0;
      background-color: transparent;
      cursor: pointer;
      color: $mediumGrey;
      font-family: Source Sans Pro;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .DropdownMenu {
    position: unset;
    &__content {
      min-width: unset !important;
      width: 100%;
      top: 80px;
    }
  }
  &__header,
  &__subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    &__arrow {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    &__title {
      font-size: 16px;
      font-weight: 600;
      color: #323246;
    }
  }
  &__header {
    position: sticky;
    top: 55px;
    z-index: 1;
    background: #f5f5fb;
    &__icon {
      width: 16px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__subheader {
    border-bottom: 1px solid #f3f3f3;
    background: #fafbfc;
    position: relative;

    .DropdownMenu__content {
      top: 0;
    }

    &__dropdownContainer {
      position: relative;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      width: 200px;
      margin-left: 20px;
    }
  }
}
