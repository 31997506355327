@import '../../../../../theme/palette.scss';

.InviteReferralsContainer {
  &__card {
    padding: 16px;
    border-radius: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--align-left {
      align-items: flex-start;
    }
  }

  &__top {
    &__title {
      color: #323246;
      font-size: 25px;
      font-weight: 700;
    }

    &__subtitle {
      text-align: center;
      color: #9e9eb8;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 14px;
    }
  }

  &__instructionStep {
    display: flex;

    &__left {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background: #f5f5fb;
      color: #7767e4;
      font-size: 16px;
      font-weight: 600;
      margin-inline-end: 12px;
    }

    &__right {
      display: flex;
      flex-direction: column;

      &__title {
        color: #323246;
        font-size: 16px;
        font-weight: 600;

        &--highlighted {
          color: #7767e4;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &__subtitle {
        color: $mediumGrey;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__acceptTc {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
