@import '../../../../../theme/palette.scss';
@import '../../../../../theme/mixins.scss';

.ApplicantReviewJobCard {
  background-color: $white;
  border-radius: 10px;
  padding: 18px 13px;
  position: relative;
  cursor: pointer;
  &__fullfillmentPercentage {
    position: absolute;
    top: 18px;
    inset-inline-end: 16px;
    border-radius: 5px;
    background: rgba($color: #fcb35c, $alpha: 0.2);
    color: #fcb35c;
    height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding: 1.5px 7px;
    cursor: pointer;
    img {
      margin-inline-start: 4px;
      height: 16px;
    }
    &--green {
      background: rgba($color: #3dd598, $alpha: 0.2);
      color: #3dd598;
    }
  }
  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    span {
      font-weight: normal;
      margin-inline-start: 4px;
      color: $mediumGrey;
      &:first-child {
        @include truncate;
        font-weight: 600;
        color: $darkGrey;
      }
    }
  }
  &__details {
    display: flex;
    align-items: center;
    color: $lightGrey;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    img {
      margin-inline-end: 8px;
    }
  }
}
