@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.AddUser {
  &__container {
    background-color: $white;
    padding-top: 27px;
    padding-bottom: 20px;
    padding-inline-start: 13px;
    padding-inline-end: 13px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    &__form {
      width: 100%;
      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0;
        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          &.submit {
            background-color: $primary;
            color: $white;
          }
          &.delete {
            color: $pink;
            border-color: $pink;
          }
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &__inviteButton {
    @include primaryButton;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
    &:hover {
      color: $white;
    }
  }
}
