@import '../../../theme/palette.scss';

.Input {
  display: flex;
  height: 56px;
  position: relative;

  &__input {
    width: 100%;
    border-radius: 5px;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    border: 1px solid #cbcbe2;
    color: $darkGrey;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    background-color: $white;

    &.active {
      padding-top: 20px;
    }

    &:focus {
      border: 1px solid $primary;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    &[type='email'] {
      direction: ltr;
    }
  }

  &__labelContainer {
    position: absolute;
    top: 8px;
    z-index: 1;
    margin-top: 0;
    margin-bottom: 0;
    margin-inline: 15px;

    &__label {
      font-size: 13px;
      font-weight: 400;
      color: #9e9eb8;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.fullContainer {
      top: 0px;
      left: 0px;
      padding-inline-start: 16px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: $white;
      border: 1px solid #cbcbe2;

      span {
        font-size: 16px;
      }
    }
  }

  &__imgContainer {
    position: absolute;
    top: 50%;
    inset-inline-end: 16px;
    transform: translateY(-50%);
  }
}
