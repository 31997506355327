@import '../../../theme/palette.scss';

.InvoiceOgrammer {
  &__sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__img {
      width: 20px;
      height: 20px;
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      color: $darkGrey;
    }
  }

  &__section {
    background-color: $white;
    border-radius: 10px;
    padding: 11px 18px 0 22px;

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 14px 0;
      border-bottom: 1px solid #cbcbe2;

      &__label {
        font-size: 16px;
        color: $lightGrey;
        font-weight: 400;
      }

      &__value {
        font-size: 16px;
        color: $darkGrey;
        font-weight: 400;
        text-align: end;
      }

      &:last-of-type {
        border-bottom: unset;
      }
    }
  }

  &__shiftsSection {
    background-color: $white;
    border-radius: 10px;
    overflow: hidden;

    &__shiftRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;

        &__label {
          font-size: 16px;
          font-weight: 400;
          color: $lightGrey;
        }

        &__value {
          font-size: 16px;
          font-weight: 400;
          color: $darkGrey;
        }
      }

      &.even {
        background-color: #f9f9f9;
      }
    }
  }
}
