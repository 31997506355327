@import '../../../../../theme/palette.scss';
@import '../../../../../theme/mixins.scss';

.LocationItem {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 11.33px 10px 14px;
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__nameContainer {
    display: flex;
    align-items: center;
  }
  &__locationIcon {
    width: 15px;
  }
  &__name {
    margin-bottom: 0;
    margin-inline-start: 6px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $darkGrey;
  }
  &__address {
    @include truncate;
    max-width: 100%;
    margin-inline-start: 21px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $lightGrey;
    @media screen and (min-width: 500px) {
      max-width: 100%;
    }
  }
}
