@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.OrderDraftSummary {
  background-color: $background;
  &__container {
    @include fullScreen;
    padding-top: 34px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  &__sendForReviewButton {
    @include primaryButton;
    width: 263px;
    height: 56px;
    margin: 0 auto;
    display: block;
    margin-bottom: 39px;
    transition: 0.25s all;
    &.disabled {
      border: none;
    }
  }
  &__amendButton {
    border: none !important;
    width: unset !important;
    font-size: 16px;
    font-weight: 400;
  }
}
