@import '../../theme/palette.scss';
@import '../../theme/global-variables.scss';

.DropdownMenu {
  position: relative;
  display: inline-block;

  > img {
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    max-height: 240px;
    overflow-y: auto;

    &--fullWidth {
      width: 100%;
      top: unset;
    }
    position: absolute;
    min-width: 55vw;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0px 8px 16px 0px #c8cce5;
    padding: 5px 0;
    z-index: 1;
    right: 0;
    top: 0;

    @media screen and (min-width: $minDesktopWidth) {
      min-width: 200px;
    }
  }

  &__item {
    cursor: pointer;
    padding: 12.5px 15px;

    &:hover {
      background-color: $veryLightGrey;
    }
  }
}
