@import '../../../../../theme/global-variables.scss';

.SkillMatch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8.5px 15px 8.5px 6px;
  border-radius: 5px;
  background: rgba(211, 213, 218, 0.2);
  @media screen and (min-width: $minDesktopWidth) {
    padding: 8px 16px 8.5px 8px;
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    color: #9e9eb8;
    @media screen and (min-width: $minDesktopWidth) {
      font-size: 15px;
    }
  }

  &__percent {
    font-size: 12px;
    font-weight: 500;
    color: #272755;
    @media screen and (min-width: $minDesktopWidth) {
      font-size: 15px;
    }
  }

  &__images {
    & img {
      max-width: 24px;
      max-height: 24px;

      &:not(:last-child) {
        margin-inline-end: 6px;
      }
    }
  }
}
