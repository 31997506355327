@import '../../theme/mixins';
@import '../../theme/palette';
@import './theme/mixins';

.SignUp {
  &__phoneContainer {
    height: 56px;
  }

  &__phoneDropdownButton {
    height: 54px !important;
    top: 1px !important;
    left: 1px !important;
    border: none !important;
    border-right: 1px solid #cacaca !important;
    border-radius: 5px 0 0 5px !important;

    .selected-flag {
      width: 50px !important;
      padding: unset !important;
      padding-inline-start: 15px !important;
      & .arrow {
        inset-inline-start: 20px;
        left: unset !important;
      }
    }
  }

  &__phone {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px !important;
    border: 1px solid #cbcbe2 !important;
    color: #323246 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    position: relative !important;
    background-color: #fff !important;
    padding-inline-start: 60px !important;

    &.active {
      padding-top: 20px !important;
    }

    &:focus {
      border: 1px solid $primary !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
}
