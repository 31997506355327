.EmptyList {
  & > span {
    display: block;
    width: 100%;
    color: #6e6e87;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}
