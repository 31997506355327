@import '../../../../../../../theme/palette.scss';
@import '../../../../../../../theme/mixins.scss';

.SpColumn {
  width: 20%;
  min-width: 248px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 38px;
  padding-inline-end: 26px;
  padding-top: 15px;
  padding-bottom: 15px;
  &__img {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    margin-inline-end: 8px;
    object-fit: cover;
    cursor: pointer;
  }
  &__textContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &__name {
    display: inline-block;
    font-size: 14px;
    color: #323246;
    font-weight: 600;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }
  &__id {
    font-size: 12px;
    color: #b0b7c3;
    font-weight: 500;
    line-height: 16px;
  }
  &__candidates {
    font-size: 14px;
    color: #7094f7;
    font-weight: 400;
    line-height: 18px;
  }
}
