@import '../../../../../theme/palette.scss';
@import '../../../../../theme/global-variables.scss';

.OrderButton {
  width: 100% !important;
  background-color: $primary;
  border-radius: 5px;
  height: 48px;

  @media (min-width: $minDesktopWidth) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    color: $white;
    white-space: nowrap;
  }
}
