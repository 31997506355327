@import '../../../../../theme/palette';

.JobDraftPOC {
  display: flex;
  flex-direction: column;

  &__pocSelect {
    background-color: $white;
    &.error {
      .Select__label {
        color: $error;
      }
      .Select__input {
        border: 1px solid $error;
      }
    }
  }

  &__modal {
    padding: 0 23px;
    align-items: stretch !important;

    &__header {
      padding-top: 20px;
      padding-inline-end: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__modalCloseImg {
        margin-top: 15px;
        cursor: pointer;
        width: 15px;
        height: 15px;
        align-self: flex-end;
      }

      &__modalTitle {
        font-size: 16px;
        font-weight: 700;
        align-self: center;
        margin-top: 5px;
        margin-bottom: -10px;
      }
    }
  }
}
