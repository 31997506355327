@import '../../theme/palette.scss';

.FallbackUI {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $darkGrey;
  }
}
