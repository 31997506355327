@import '../../../../theme/palette.scss';

.OrderSummaryCostCard {
  background-color: $white;
  border-radius: 10px;
  padding: 25px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  p {
    margin-bottom: 0;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey;
    padding-top: 14px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom-width: 0;
      padding-bottom: 0;
    }
  }
  &__label {
    color: $lightGrey;
  }
  &__data {
    color: $darkGrey;
  }
  .bold {
    font-weight: 600;
  }
  &__hint {
    margin-top: 16px;
    &__label {
      font-weight: 400;
      font-size: 13px;
      color: #fcb35c;
    }
  }
}
