@import '../../../../theme/palette.scss';

.Header {
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 700;
    font-family: Roboto Slab;
    color: $darkGrey;
  }
  &__subtitle {
    margin-top: 5px;
    font-weight: 400;
    color: $lightGrey;
  }
}
