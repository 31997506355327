@import '../../../../theme/palette.scss';
@import '../../../../theme/mixins.scss';

.QuestionItem {
  border-radius: 20px;
  background-color: $white;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 14px;
  &__cameraImg {
    width: 20px;
    height: 12px;
    margin-top: 6px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    row-gap: 12px;
  }
  &__description {
    font-family: Source Sans Pro;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $darkGrey;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__linkLabel {
    margin-top: 0;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $blue;
    cursor: pointer;
  }
  &__ratingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 13px;
    &_hidden {
      visibility: hidden;
    }
  }
  &__starImg {
    width: 14px;
  }
  &__ratingLabel {
    font-family: Source Sans Pro;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $mediumGrey;
  }
}
