@import '../../../../theme/palette.scss';

.Interview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 16px;
  }
  &__headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
  }
  &__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__title {
    color: $darkGrey;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.16px;
  }
  &__subtitle {
    color: #4e5d78;
    font-family: Source Sans Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &__bottomSeparator {
    height: 1px;
    width: 100%;
    background-color: $grey;
    position: absolute;
    left: 0;
    right: 0;
  }
}
