@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.OrderConfirmationList {
  max-width: 100% !important;
  overflow-y: scroll;
  &__container {
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &__cardContainer {
      width: 100%;
      margin-top: 10px;
      cursor: pointer;
    }
    &__emptyState {
      @include emptyState;
    }
  }

  &__filtersButton {
    margin-inline-end: 20px;
  }
}
