@import '../../../../../../../theme/mixins.scss';
@import '../../../../../../../theme/palette.scss';

.Status {
  flex-grow: 1;
  height: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &.completed-shift,
  &.started-shift {
    background-color: #8ecd9c;
    .Status__label {
      color: $white;
    }
  }
  &.completed-sp,
  &.started-sp {
    background-color: rgba(142, 205, 156, 0.2);
    .Status__label {
      color: #5eaa6f;
    }
  }
  &.pending-sp {
    background-color: rgba(252, 179, 92, 0.2);
    .Status__label {
      color: #fcb35c;
    }
  }
  &.not-attended-sp {
    background-color: rgba(248, 140, 153, 0.2);
    .Status__label {
      color: #ea6f7e;
    }
  }
  &.upcoming-shift {
    background-color: #7094f7;
    .Status__label {
      color: $white;
    }
  }
  &.upcoming-sp {
    background-color: rgba(186, 190, 214, 0.2);
    .Status__label {
      color: #9393b0;
    }
  }
  &.upcoming-empty {
    background-color: rgba(252, 179, 92, 0.2);
    .Status__label {
      color: #fcb35c;
    }
  }
  &__label {
    font-family:
      Source Sans Pro,
      sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}
