@import '../../../../../theme/palette';

.JobDraftLanguages {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-inline: 1px;
  &__searchContainer {
    margin-top: -40px;
  }
  &__list {
    flex: 1;
    border: 1px solid $grey;
    border-radius: 10px;
    padding-top: 9px;
    padding-bottom: 2px;
    padding-inline-start: 16px;
    padding-inline-end: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &.error {
      border: 1px solid $error;
    }
    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 11px 0;
      cursor: pointer;
      &__label {
        font-size: 16px;
        color: $darkGrey;
        font-weight: 400;
      }
    }
  }
}
