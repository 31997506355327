@import '../../theme/palette.scss';

.DaySelector {
  width: 100%;
  &__label {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: $lightGrey;
  }
  &__days-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
