@import '../../theme/global-variables.scss';

.Onboarding {
  &__bigImg {
    height: 75%;

    @media screen and (min-width: $minDesktopWidth) {
      height: 60%;
    }
  }
}
