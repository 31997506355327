.ImagePreview {
  position: relative;
  display: flex;
  justify-content: center;
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    height: 19px;
    width: 19px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
  }
  &__image {
    max-width: 100%;
    max-height: 300px;
  }
}
