@import '../../../theme/palette.scss';

.UsersList {
  max-width: 100% !important;
  &__container {
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  &__usersAmount {
    font-size: 16px;
    font-weight: 400;
    color: $mediumGrey;
  }
  &__newUserButton {
    height: 48px;
    width: 51%;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    &__img {
      width: 15.66px;
      height: 18px;
    }
    &__label {
      color: $primary;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &__inviteContainer {
    display: flex;
    flex-direction: column;
    &__orLabel {
      color: $lightGrey;
      font-size: 16px;
      font-weight: 400;
      align-self: center;
    }
    &__inviteLabel {
      color: $primary;
      font-size: 16px;
      font-weight: 400;
      align-self: center;
    }
  }
}
