@import '../../theme/global-variables.scss';
@import '../../theme/palette.scss';

.StickyPanel {
  position: fixed;
  bottom: 0;
  inset-inline-start: 250px;
  inset-inline-end: 0;
  padding: 19px 16px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: $white;
  width: calc(100% - 280px);
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.15);
  @media (max-width: $minDesktopWidth) {
    width: 100%;
    left: 0;
  }
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin-inline-start: 50%;
    transform: translateX(-51%);
    @media (max-width: $minDesktopWidth) {
      margin-inline-start: 50%;
      width: 78%;
    }
    @media (max-width: 800px) {
      width: 90%;
    }
    @media (max-width: 340px) {
      width: 100%;
    }
  }
}

[dir='rtl'] .StickyPanel {
  &__content {
    transform: translateX(51%);
  }
}
