.ActionAlert {
  display: flex;
  justify-content: space-between;
  border: 1px solid #fcb35c;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  padding: 12px;
  align-items: center;
  position: relative;

  &__left {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-inline-end: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    width: 80%;
  }

  &__actionText {
    font-weight: 600;
    font-size: 14px;
    color: #fcb35c;
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 11px;
    cursor: pointer;
  }
}
