.SkillsList {
  margin-top: 11px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__skill {
    margin-inline-end: 9px;
    margin-top: 11px;
    border-radius: 5px;
    padding: 4px 10px;
    cursor: pointer;

    &__name {
      margin-inline-end: 3px;
      font-weight: 400;
      font-size: 12px;
    }

    &__rating {
      font-weight: 600;
      font-size: 12px;
    }

    &--high {
      background: rgba(61, 213, 152, 0.1);

      .SkillsList__skill__name,
      .SkillsList__skill__rating {
        color: rgb(61, 213, 152);
      }
    }

    &--medium {
      background: rgba(252, 179, 92, 0.1);

      .SkillsList__skill__name,
      .SkillsList__skill__rating {
        color: rgb(252, 179, 92);
      }
    }
  }
}
