@import '../../../../../../theme/palette.scss';

.Ogrammer {
  display: flex;
  flex-direction: row;
  padding: 22px 0 18px 0;
  border-bottom: 1px solid $grey;

  &__photo {
    width: 30px;
    height: 30px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__name {
      font-size: 14px;
      color: $darkGrey;
      font-weight: 600;
    }

    &__stats {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__label {
          font-size: 12px;
          color: #b5b5be;
          font-weight: 400;
        }

        &__value {
          font-size: 16px;
          color: $mediumGrey;
          font-weight: 400;
        }
      }
    }
  }

  &__arrowImg {
    width: 5px;
    height: 10px;
    align-self: center;
  }
}
