@import '../../../../../../theme/global-variables.scss';

.CalendarLocationFilter {
  margin-inline-end: 10px;
  &__select {
    width: 300px;
    @media (max-width: $maxMobileWidth) {
      width: 220px;
    }
  }
}
