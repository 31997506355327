@import '../../../../../../../../../../theme/palette.scss';

.MenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.disabled {
    opacity: 0.5;
  }
  &.link {
    font-family:
      Source Sans Pro,
      sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $darkGrey;
  }
  img {
    width: 20;
    height: 20;
  }
}
