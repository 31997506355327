@import '../../../../theme/palette.scss';

.Location {
  &__locationSelect {
    background-color: $white;
  }

  &__addLocationButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    width: 70%;
    margin: 0 auto;

    &__img {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }

    &__title {
      font-size: 16px;
    }
  }

  &__modal {
    padding: 0 23px;

    &__modalCloseImg {
      cursor: pointer;
      width: 15px;
      height: 15px;
      align-self: flex-end;
    }

    &__modalTitle {
      font-size: 16px;
      font-weight: 700;
      align-self: center;
      margin-top: 5px;
      margin-bottom: -10px;
    }
  }
}
