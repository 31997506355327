@import '../../../theme/palette';

.JobDraftDetails {
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $darkGrey;
  }
  &__row {
    display: flex;
    flex-direction: row;
  }
  &__submitButton {
    background-color: $primary;
    color: $white;
  }
  &__bottomError {
    font-size: 16px;
    font-weight: 400;
    color: $lightRed;
  }
  &__stepper {
    margin-top: auto;
  }
  &__filledAlert {
    display: flex;
    border-radius: 8px;
    background: rgba(119, 103, 228, 0.2);
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    & span {
      font-weight: 600;
      font-size: 14px;
      color: #323246;
    }

    & button {
      border-radius: 8px;
      border: 1px solid #7767e4;
      padding: 8px 16px;
      background: transparent;
      color: #7767e4;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
