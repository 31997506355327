@import '../../theme/palette.scss';
@import '../../theme/global-variables.scss';

.PageOnboarding {
  position: absolute;
  z-index: 250;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    padding: 25px 23px;
    overflow-y: scroll;

    /* Hide scrollbar but keep scroll functionality */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__closeContainer {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &__img {
        width: 28.49px;
        height: 29.08px;
      }

      &__closeLabel {
        font-size: 12px;
        color: $white;
      }
    }

    &__description {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: $white;
      white-space: pre-wrap;

      @media screen and (min-width: $minDesktopWidth) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  &.hidden {
    display: none;
  }
}
