.InviteReferralPopup {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: #323246;
    font-size: 25px;
    font-weight: 700;
  }

  &__subtitle {
    color: #9e9eb8;
    font-size: 14px;
    font-weight: 400;
  }

  &__subtitle2 {
    color: #9e9eb8;
    font-size: 12px;
    font-weight: 400;
  }

  &__socialShare {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;

    & button {
      margin-inline-end: 16px;
    }
  }
}
