@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/global-variables.scss';

$btnHeight: 37px;

.AcceptAndRejectSection {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  @media screen and (min-width: $minDesktopWidth) {
    margin-top: 16px;
  }
  @media screen and (min-width: 450px) {
    justify-content: space-around;
  }
  &__accept {
    @include primaryButton;
    width: 131px;
    height: $btnHeight;
    box-shadow: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  &__reject {
    @include secondaryGreyButton;
    width: 131px;
    height: $btnHeight;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  &__accepted {
    width: 100%;
    height: $btnHeight;
    background: #5eaa6f;
    border-radius: 5px;
    color: #fff;
    font-family: Poppins;
    cursor: default;
    font-weight: 600;
  }
}
