@import '../../theme/palette.scss';

.GrStartPage {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
  }

  &__title {
    margin: 0;
    font-family: Roboto Slab;
    font-weight: 700;
    font-size: 24px;

    @media (min-width: 360px) {
      font-size: 25px;
    }

    .highlighted {
      color: $primary;
      text-transform: capitalize;
    }
  }

  &__welcome-text {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #183b56;
    margin-top: 16px;
    text-align: center;
  }

  &__desc-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #183b56;
    margin-top: 12px;
    text-align: center;
  }

  &__slide {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    cursor: pointer !important;

    & > img {
      margin-top: 40px;
    }

    &__title {
      margin-top: 50px;
      font-weight: 700;
      font-size: 25px;
      color: #183b56;
      align-self: flex-start;
    }

    &__subtitle {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      color: $mediumGrey;
      align-self: flex-start;
    }
  }

  & .ant-carousel {
    position: relative;
    @media (max-width: 650px) {
      width: 90%;
      align-self: center;
    }

    .slick-slide {
      height: unset;
    }

    .slick-dots {
      li.slick-active {
        width: 8px;
        button {
          background: #272755;
          opacity: 1;
        }
      }
    }
    .slick-dots-bottom {
      bottom: -35px;
      z-index: 2;
      li {
        width: 8px;
        button {
          border-radius: 100px;
          width: 5px;
          height: 5px;
          background: #272755;
          opacity: 0.2;
        }
      }
    }
  }

  &__actionButton {
    background: #7767e4;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 0;
    margin-top: 60px;
    width: 320px;
    align-self: center;
  }
}
