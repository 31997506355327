@import '../../../../../../theme/mixins.scss';
@import '../../../../../../theme/palette.scss';
@import '../../../../../../theme/global-variables.scss';

.modalReportIssue {
  &__header {
    margin-top: 15px;
  }
  &__title {
    font-size: 25px;
    font-weight: 700;
  }
  &__body {
    margin-top: 30px;
    width: 100%;
    padding: 0 25px;
  }
  &__textArea {
    min-height: 150px;
    width: 100%;
    border: 1px solid $grey;
    border-radius: 5px;
    padding: 10px 5px 10px 11px;
    outline: none;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    &__button {
      padding: 17px 115px;
      min-width: 350px;
      box-shadow: none !important;
      @media (max-width: $minDesktopWidth) {
        min-width: 100%;
      }
      &--submit {
        @include primaryButton;
      }
      &--cancel {
        @include secondaryButton;
        margin-top: 10px;
      }
    }
  }
}

.error-msg {
  @include errorMessage;
}
