@import '../../../../../theme/palette';

.JobDraftExperience {
  display: flex;
  flex-direction: column;
  &__innerContainer {
    display: flex;
    flex-direction: column;
    padding: 0 19px;
    &__button {
      width: 100% !important;
      height: 56px !important;
      border-color: $grey !important;
      color: $darkGrey;
      &__content {
        display: flex;
        flex-direction: column;
      }
      &__label {
        &.error {
          color: $error;
        }
      }
      &.error {
        border-color: $error !important;
      }
      &.active {
        background-color: $primary;
        color: $white;
      }
      &.active &__content__description {
        color: $white;
      }
      &:not(.active) &__content__description {
        color: rgba(110, 110, 135, 1);
      }
    }
    &__hint {
      font-size: 13px;
      color: $orange;
      font-weight: 400;
      text-align: center;
    }
  }
}
