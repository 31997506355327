@import '../../../../theme/palette.scss';

.SectionTitle {
  display: flex;
  align-items: center;

  &__icon {
    margin-inline-end: 5px;
  }

  &__text {
    color: #323246;
    font-size: 16px;
    font-weight: 700;
    margin-inline-end: 5px;
  }

  &__statText {
    color: $mediumGrey;
    font-size: 16px;
    font-weight: 400;
  }
}
