@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/global-variables.scss';

@mixin auth__container {
  @include fullScreen;
  display: flex;
  flex-direction: column;
  margin: 63px 0 20px 0;

  @media (min-width: $minDesktopWidth) {
    margin: 63px 0 0 0;
  }
}

@mixin auth__logo {
  width: 120px;
  height: 26px;
}

@mixin auth__title {
  margin: 0;
  line-height: 33px;
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 25px;
  color: $darkGrey;
}

@mixin auth__subtitle {
  margin: 0;
  line-height: 20px;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-size: 16px;
  color: $mediumGrey;
}

@mixin auth__button {
  @include primaryButton;
  width: 100%;
  height: 56px;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-size: 16px;
}
