.QrScanner {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    overflow-y: scroll;
  }

  &__top {
    display: flex;
    justify-content: flex-end;
  }

  &__closeContainer {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__bottom {
    display: flex;
    justify-content: center;
  }

  &__info {
    width: 100%;
    background: #eeecfc;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    padding: 8px 12px;

    &__title {
      margin-bottom: 12px;
      color: #7767e4;
      font-size: 16px;
      font-weight: 600;
    }

    &__blocks {
      display: flex;
      justify-content: space-between;
    }

    &__block {
      &__top {
        & img {
          margin-inline-end: 5px;
        }

        &__title {
          color: #323246;
          font-size: 16px;
          font-weight: 400;
        }
      }

      &__bottom {
        color: #6e6e87;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &__cameraPermission {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5f5fb;
    padding: 0 20px;
    width: 100%;
    height: 300px;
    max-width: 650px;
    &__iconContainer {
      background: rgba(119, 103, 228, 0.1);
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &--disabled {
        background: rgba(248, 140, 153, 0.1);
      }
    }
    &__title {
      color: #6e6e87;
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      &--disabled {
        color: #ea6f7e;
      }
    }
    &__subtitle {
      color: #6e6e87;
      font-size: 14px;
      font-weight: 400;
      margin-top: 3px;
      text-align: center;
    }
  }
  &__manualInput {
    margin-top: 20px;
    width: 100%;
    background: #fff;
    border-radius: 16px;
    max-width: 650px;
    padding: 8px;
    &__header {
      &__message {
        color: #6e6e87;
        font-size: 14px;
        font-weight: 600;
        &--highlight {
          color: #ea6f7e;
        }
      }
    }
    &__form {
      display: flex;
      width: 100%;
      align-items: center;
    }
    &__input {
      border-radius: 8px;
    }
    &__submit {
      margin-inline-start: 10px;
      padding: 14px 21px;
      border-radius: 8px;
      background: #5eaa6f;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
