@import '../../theme/palette.scss';

.Toggle {
  height: 30px;
  width: 53px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.active {
    .Toggle__background {
      background-color: $primary;
    }
    .Toggle__switch {
      inset-inline-start: 23px;
      background-color: $primary;
    }
  }
  &__background {
    height: 16px;
    background-color: $lighterGrey;
    border-radius: 8px;
    transition: background-color 0.5s 0.1s;
  }
  &__switch {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: $lightGrey;
    position: absolute;
    inset-inline-start: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
      inset-inline-start 0.5s,
      background-color 0.5s;
    &__label {
      font-size: 13px;
      color: $white;
      user-select: none;
    }
  }
}
