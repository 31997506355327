@import '../../../../theme/palette.scss';
@import '../../../../theme/mixins.scss';

.ShiftCard {
  background-color: $white;
  border-radius: 10px;
  padding: 18px 13px;
  position: relative;
  &__leftTime {
    position: absolute;
    top: 18px;
    inset-inline-end: 16px;
    border-radius: 5px;
    background: rgba($color: #fcb35c, $alpha: 0.2);
    color: #fcb35c;
    height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding: 1.5px 7px;
    cursor: pointer;
    img {
      margin-inline-start: 4px;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $darkGrey;
    display: flex;
    span {
      &:first-child {
        @include truncate;
      }
    }
    &__amount {
      font-weight: normal;
    }
  }
  &__details {
    display: flex;
    align-items: center;
    color: $lightGrey;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    img {
      margin-inline-end: 8px;
    }
  }
}
