.UploadFile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &__input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &__placeholderImage {
    width: 100px;
  }
  &__image-container {
    position: relative;
  }
  &__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  &__photo-icon {
    position: absolute;
    left: calc(100px / 2 - 10px);
    top: calc(100px / 2 - 10px);
    width: 20px;
    height: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &__remove {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
}
