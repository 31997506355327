@import '../../../../../theme/palette';

.ShiftTimingsPrompt {
  display: flex;
  flex-direction: column;
  &__innerContainer {
    margin-top: 95px;
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    &__button {
      width: 100% !important;
      color: $darkGrey;
      height: 56px !important;
      border: 1px solid $grey !important;
      &.error {
        border: 1px solid $error !important;
        color: $error;
      }
      &.active {
        background-color: $primary;
        border: 1px solid $primary !important;
        color: $white;
      }
    }
  }
}
