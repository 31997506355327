.AcceptTcLink {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  &__text {
    color: #9e9eb8;
    font-size: 13px;
    font-weight: 400;
  }
}
