@import '../../../../../../../../../../theme/palette.scss';

.ShiftTools {
  display: flex;
  justify-content: center;
  align-items: center;
  &__dropdownContainer {
    padding: 10px;
    cursor: pointer;
  }
}
