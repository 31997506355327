@import '../../../theme/mixins.scss';

.SignSLA {
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  &__body {
    @include fullScreen;
    width: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  &__back {
    background-color: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  }
  &__title {
    @include title;
    line-height: 140%;
    margin: 0 auto 0;
  }
  &__terms {
    background-color: #fff;
    height: 75vh;
    width: 323px;
    overflow: scroll;
    border-radius: 16px;
  }
  &__agreeButton {
    @include primaryButton;
    width: 177px;
    height: 48px;
    font-family: Source Sans Pro;
    display: block;
    margin: auto auto 24px;
    border-radius: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 27px;
    box-shadow: 0px 10px 30px rgba(119, 103, 228, 0.2);
    background-color: $blue;
    img {
      margin-inline-end: 25px;
    }
  }
}
