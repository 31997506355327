.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@keyframes ldio-28vulqui936 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-28vulqui936 div {
  position: absolute;
  animation: ldio-28vulqui936 1s linear infinite;
  width: 120px;
  height: 120px;
  top: 40px;
  left: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #9193e2;
  transform-origin: 60px 62px;
}
.loadingio-spinner-eclipse-zqbi37icss9 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-28vulqui936 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-28vulqui936 div {
  box-sizing: content-box;
}
