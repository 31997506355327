.AffiliateTcModal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    &__title {
      color: #323246;
      font-size: 25px;
      font-weight: 700;
    }
    &__subtitle {
      color: #9e9eb8;
      font-size: 14px;
      font-weight: 400;
      align-self: flex-start;
    }
    &__tc {
      cursor: pointer;
      margin-top: 20px;
      &__text {
        color: #9e9eb8;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
