@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.JobDraftLocation {
  display: flex;
  flex-direction: column;

  &__Popup__subtitle {
    @media (max-width: $minDesktopWidth) {
      margin-bottom: 0 !important;
    }
  }

  &__modalHeader {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 13px;

    &__modalCloseImg {
      cursor: pointer;
      width: 15px;
      height: 15px;
    }

    &__modalTitle {
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__locationSelect {
    @media (max-width: $minDesktopWidth) {
      margin-top: 20px;
    }
  }

  &__addLocationButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    width: 70%;
    margin: 0 auto;

    &__img {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }

    &__title {
      font-size: 16px;
    }
  }
}
