@import '../../theme/palette.scss';

.LeadForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__errors {
    font-size: 14px;
    color: $lightRed;
  }
}
