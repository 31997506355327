@import '../../../../theme/palette.scss';

.Description {
  &__textarea {
    width: 100%;
    border: 1px solid $grey;
    border-radius: 5px;
    padding: 10px 5px 10px 11px;
    min-height: 100px;
    outline: none;
  }
}
