@import '../../../../../../theme/palette.scss';
@import '../../../../../../theme/mixins.scss';

.CalendarWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__calendar {
    overflow-y: scroll;
    flex: 1;
    max-height: 80vh;
    background-color: white;
    border-radius: 12px;
  }
  &__spacer {
    flex: 0.05;
  }
}
