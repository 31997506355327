@import '../../../../theme/mixins.scss';

.ToggleFavourite {
  @include transparentBackground;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  top: 24px;
  right: 16px;
  border: 1px solid $grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
