@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.CompleteRegisteration {
  background-color: $background;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__subTitle {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
  }

  &__body {
    @include fullScreen;
    width: 100%;
    margin-bottom: 25px;
    background-color: $white;
    border-radius: 10px;
    padding: 19px 11px 23px 11px;
  }

  &__uploadInputLable {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    margin-top: -18px;
    margin-bottom: 0;
  }

  &__errorMessage {
    @include errorMessage;
    margin-top: 2px;
  }

  &__termsConditions {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__checkbox {
      cursor: pointer;
      margin-top: 2px;
    }

    &__container {
      font-size: 15px;
      font-weight: 400;

      &__link {
        color: $purple;
        font-size: 15px;
        font-weight: 700;
        margin-top: 0;
      }
    }
  }

  &__submitButton {
    @include primaryButton;
    margin: 22px auto 0;
    display: block;
    height: 56px;
    width: percentage(263/326);
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
  }
}
