@import '../../theme/palette.scss';

.MarketSelector {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;

  &__countryImg {
    width: 24px;
    height: 24px;
  }

  &__codeContainer {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  &__countryCode {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__title {
      font-size: 16px;
      font-weight: 400;
      color: $darkGrey;
    }

    & img {
      height: 20px;
      width: 20px;
    }

    &:hover,
    &--active {
      background-color: $veryLightGrey;
    }

    &:hover &__title,
    &--active &__title {
      color: #1890ff;
    }
  }
}

.DropdownMenu__content {
  @media (min-width: 1366px) {
    min-width: 240px !important;
  }
}
