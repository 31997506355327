@import '../../../../../theme/palette.scss';

.CompanyName {
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-inline-start: 25px;
    padding-inline-end: 12.67;
    &.clickable {
      cursor: pointer;
    }
    &__label {
      font-size: 13px;
      font-weight: 400;
      color: $lightGrey;
      line-height: 20px;
    }
    &__name {
      font-size: 16px;
      font-weight: 400;
      color: #171725;
      text-transform: uppercase;
    }
    &__img {
      transition: transform 0.5s;
      user-select: none;
      &.turned {
        transform: rotate(-90deg);
      }
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
  }
  &__dropdown {
    transition: max-height 1s ease-out;
    &.collapsed {
      transition:
        max-height 1s ease-out,
        opacity 0.5s 0s;
      max-height: 0 !important;
      opacity: 0;

      & > div {
        display: none;
      }
    }
    &__item {
      cursor: pointer;
      padding-top: 3px;
      padding-bottom: 3px;
      margin-inline-start: 25px;
      padding-inline-end: 12.67;
      color: $lightGrey;
      font-size: 14px;
    }
  }
}
