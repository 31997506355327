@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/global-variables.scss';

.ApplicantDetails {
  margin: 0 -5px;
  &__header {
    background-color: $white;
    border-radius: 20px;
    padding: 24px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__toggleFavourite {
    align-self: flex-end;
  }
  &__closeButton {
    @include transparentBackground;
    position: absolute;
    top: 18px;
    inset-inline-start: 29px;
  }
  &__avatarContainer {
    position: relative;
    border-radius: 50%;
    background-color: #e5e5ed;
    &__avatar {
      object-fit: cover;
      object-position: center;
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    &__beacon {
      position: absolute;
      width: 24px;
      height: 24px;
      inset-inline-end: 0;
      bottom: 0;
    }

    & .ant-image-mask {
      border-radius: 100%;
    }
  }
  &__nameAndRatingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__chiefContainer {
      margin-top: 14px;
      height: 24px;
      border-radius: 8px;
      background-color: rgba(145, 147, 226, 0.1);
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &__beacon {
        width: 16px;
        height: 16px;
        margin-inline-end: 3px;
      }
      &__label {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $blue;
      }
    }
  }
  &__name {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    margin-top: 25px;
  }
  &__shiftsCount {
    color: $mediumGrey;
    margin-top: 5px;
    margin-bottom: 13px;
  }
  &__topQualitiesContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 18px;
  }
  &__topQuality {
    margin-inline-start: 13px;
    margin-bottom: 13px;
  }
  &__seniorityLevel {
    margin-bottom: 30px;
  }
  &__informationContainer {
    background-color: $white;
    border-radius: 20px;
    padding: 24px 25px;
  }
  &__infoTitle {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-bottom: 0;
  }
  &__infoContainer {
    background-color: $white;
    border-radius: 20px;
    padding: 24px 25px;
    &__title {
      font-size: 16px;
      font-weight: 700;
      color: $darkGrey;
    }
    &__separator {
      height: 1px;
      background-color: $grey;
      margin: 16px 0;
      opacity: 0.5;
    }
  }
  &__separator {
    height: 21px;
  }
  &__controlContainer {
    inset-inline-start: 0;
    inset-inline-end: 0;
    padding: 19px 16px;
    background-color: $white;
    width: 100%;
    box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.15);
    position: sticky;
    bottom: 0;
    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 600px;
      margin-inline-start: 50%;
      transform: translateX(-50%);
      @media (max-width: $minDesktopWidth) {
        margin-inline-start: 50%;
        transform: translateX(-51%);
        width: 78%;
      }
      @media (max-width: 800px) {
        width: 90%;
      }
      @media (max-width: 340px) {
        width: 100%;
      }
    }
    &__accepted {
      width: 80%;
      background-color: $lightGreen;
      border-color: $lightGreen;
      .Button__label {
        color: $white;
      }
    }
    &__rejected {
      width: 80%;
      background-color: $pink;
      border-color: $pink;
      color: $white;
    }
    &__imgContainer {
      margin: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $background;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &__buttonsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &__rejectButton {
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid $pink;
        background-color: $white;
        color: $pink;
        width: 93px;
      }
      &__acceptButton {
        width: 93px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        color: $white;
      }
    }
  }
}
