@import '../../../theme/palette.scss';

.JobRating {
  &__container {
    background-color: $white;
    border-radius: 10px;
    padding: 17px 16.5px;
    &__title {
      font-size: 16px;
      font-weight: 600;
      color: $darkGrey;
      &__amount {
        font-weight: 400;
      }
    }
    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__label {
        font-size: 16px;
        font-weight: 400;
        color: $lightGrey;
      }
    }
    &__subtitle {
      color: $darkGrey;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    &__description {
      color: $lightGrey;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    &__overallRatingError {
      display: flex;
      flex-direction: column;
      & > .FieldError__label {
        display: inline-block;
        margin-top: 15px;
        align-self: center;
      }
    }
    .Rating {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .RateCard:last-of-type {
      border-bottom: unset;
    }
  }
  &__confirmButton {
    color: $white;
    background-color: $primary;
    &.disabled {
      opacity: 0.5;
    }
  }
}
