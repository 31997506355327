@import '../../../../theme/mixins.scss';
@import '../../../../theme/palette.scss';

.OrderSummaryShiftsModal {
  display: flex;
  padding-top: 37px;
  flex-direction: column;
  max-height: 476px;
  overflow-y: auto;
  padding-bottom: 19px;

  &__modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: $darkGrey;
  }

  &__subTitle {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $lightGrey;
  }

  &__shifts {
    height: 52px;
    display: flex;
    align-items: center;
    padding-inline-start: 12px;
    padding-inline-end: 10px;
    justify-content: space-between;
    background-color: #f8f8f8;
    margin-bottom: 12px;
    border-radius: 8px;
  }

  &__day {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $darkGrey;
  }

  &__time {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: $lightGrey;
  }

  p {
    margin-bottom: 0;
  }

  &__dragIcon {
    width: 36px;
    display: block;
    margin: 0 auto;
  }
}
