@import '../../../../../../theme/palette.scss';
@import '../../../../../../theme/mixins.scss';
@import '../../../../../../theme/global-variables.scss';

.CalendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $maxMobileWidth) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  &__container {
    padding-top: 23.5px;
    padding-bottom: 27px;
    padding-inline-start: 13px;
    padding-inline-end: 10px;
  }
  @media screen and (min-width: $minDesktopWidth) {
    padding-inline-end: 64px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__label {
    font-family: Inter;
    font-size: 20px;
    color: #4e5d78;
    font-weight: 600;
    margin-right: 20px;
    @media (max-width: $maxMobileWidth) {
      font-size: 14px;
    }
    &--dimmed {
      color: #c1c7d0;
      font-weight: 500;
      margin-inline-start: 3px;
    }
  }
  &__weekToggle {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    @media (max-width: $maxMobileWidth) {
      margin-top: 15px;
    }

    &__calendar {
      left: 136px;
      @media (max-width: $maxMobileWidth) {
        left: 0;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    @media screen and (min-width: $minDesktopWidth) {
      gap: 25.5px;
    }
    @media (max-width: 530px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    &__toggle {
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      background-color: #fafbfc;
      &__arrowContainer {
        cursor: pointer;
        &--right {
          margin-left: 17px;
        }
        &--left {
          margin-right: 17px;
        }
        &__arrow {
          width: 10px;
          height: 18px;

          &.flipped {
            -moz-transform: scale(-1, -1);
            -o-transform: scale(-1, -1);
            -webkit-transform: scale(-1, -1);
            transform: scale(-1, -1);
          }
        }
      }
      &__separator {
        height: 100%;
        width: 0.7px;
        background-color: #f3f3f3;
      }
    }
    &__currentWeekButton {
      border-radius: 8px;
      border: 1.5px solid #cbcbe2;
      background: transparent;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 400;
      color: #6e6e87;
      min-width: 120px;
    }
  }
}

.mobile-only {
  display: none;
  @media (max-width: $maxMobileWidth) {
    display: flex;
  }
}

.desktop-only {
  display: none;
  @media (min-width: $minDesktopWidth) {
    display: flex;
  }
}
