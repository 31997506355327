@import '../../../theme/palette.scss';

.KnowledgeCenterItem {
  &__list {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $white;
    border-radius: 10px;
    list-style-type: none;
    &__header {
      cursor: pointer;
      padding-inline-start: 16px;
      height: 50px;
      background-color: $mediumGrey;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(203, 203, 226);
      &__title {
        font-weight: 600;
        font-size: 16px;
        color: $white;
      }
    }
    &__item {
      margin-inline-start: 12px;
      margin-inline-end: 16px;
      height: 50px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__label {
        font-size: 14px;
        font-weight: 600;
        padding-inline-start: 5px;
      }
      &__img {
        width: 5px;
        height: 10px;
      }
    }
    &__separator {
      border-bottom: 1px solid rgb(203, 203, 226);
      margin-inline-start: 12px;
      margin-inline-end: 16px;
      &:last-of-type {
        display: none;
      }
    }
  }
}
