@import '../../theme/palette.scss';
@import '../../theme/mixins.scss';

.UploadDocument {
  &__uploadInput {
    display: none;
  }
  &__upload {
    display: block;
    height: 61px;
    border: 1px dashed $grey;
    background-color: $veryLightGrey;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    p {
      color: $primary;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
      a {
        font-size: 16px;
      }
    }
  }
  &__errorMessage {
    @include errorMessage;
    margin-top: 2px;
  }
}
