.SpRatingPopup {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 25px;
    font-weight: 700;
    color: #323246;
    margin-bottom: 16px;
    align-self: center;
  }

  &__alert {
    border-radius: 8px;
    background: rgba(119, 103, 228, 0.2);
    padding: 8px;
    margin-bottom: 16px;

    & img {
      margin-right: 6px;
    }

    &__text {
      margin-bottom: 16px;

      &__highlight {
        font-size: 14px;
        font-weight: 600;
        color: #7767e4;
      }

      &__common {
        font-size: 14px;
        font-weight: 600;
        color: #6e6e87;
      }
    }
  }

  &__sectionTitle {
    font-size: 16px;
    font-weight: 700;
    color: #323246;
    margin-bottom: 8px;
  }

  &__spList {
    overflow-y: scroll;
    flex: 1;
    max-height: 40vh;
    background-color: white;
    border-radius: 12px;
    margin-bottom: 16px;
  }
}
