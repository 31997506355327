@import '../../../../../../theme/palette.scss';
@import '../../../../../../theme/mixins.scss';

.CalendarModificationsControl {
  align-self: stretch;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: white;
  &__cancel {
    width: unset;
    margin: 0;
  }
  &__applyChanges {
    width: unset;
    padding: 0 24px;
    background-color: $blue;
    color: $white;
    margin: 0;
  }
}
