@import '../../../../theme/mixins.scss';
@import '../../../../theme/palette.scss';
@import '../../theme/mixins.scss';

.Login {
  @include auth__container;

  &__logo {
    @include auth__logo;
  }

  &__title {
    @include auth__title;
  }

  &__subtitle {
    @include auth__subtitle;
  }

  &__button {
    @include auth__button;
  }

  &__underform {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a {
      margin-top: 0;
      color: $primary;
      font-family: Source Sans Pro;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__topContainer {
    display: flex;
    justify-content: space-between;
  }

  &__SignUp_link {
    color: #7f72d9;
    font-weight: 400;
    font-size: 16px;
    align-self: center;
    margin-top: 20px;
  }
}
