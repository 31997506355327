@import '../../../../theme/palette.scss';

.EmptyOrderList {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__calendarImg {
    width: 165.72px;
    height: 120px;
  }

  &__title {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    color: $mediumLightGrey;
    text-align: center;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $mediumDarkGrey;
    text-align: center;
    white-space: pre;
  }

  &__createOrderButton {
    background-color: $primary;
    color: $white;
    width: 100% !important;
  }
}
