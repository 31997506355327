.StripeForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 25px 18px 18px 18px;

  &__form {
    width: 100%;
  }

  &__submit {
    margin-top: 30px;

    & button {
      padding: 18px 79px;
      background: #7767e4;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__saveCardContainer {
    cursor: pointer;
    margin-top: 15px;
    display: flex;

    & span {
      font-size: 15px;
      color: rgb(48, 49, 61);
      margin-inline-start: 8px;
    }
  }

  &__saveCardToggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;

    &__label {
      font-weight: 400;
      font-size: 16px;
      color: #323246;
    }
  }

  &__securityMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    &__text {
      color: #b0b7c3;
      font-weight: 400;
      font-size: 14px;
      margin-inline-start: 5px;
    }
  }

  &__additionalContent {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &__cancelButton {
    font-weight: 400;
    font-size: 16px;
    color: #ea6f7e;
    cursor: pointer;
  }
}

.PayButton {
  &__button {
    padding: 11px 80px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    background: #7767e4;
    border-radius: 5px;
    outline: none;
  }
}

.hidden {
  display: none;
}
