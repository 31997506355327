@import '../../../theme/mixins.scss';

.ApplicantsReviewPage {
  background-color: $background;
  overflow-y: scroll;
  &__container {
    @include fullScreen;
    width: 100%;
    margin: 0 auto;
  }
  &__noJobs {
    text-align: center;
    margin-top: 100px;
  }
}
