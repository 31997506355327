@import '../../../../theme/palette.scss';

.AccountStatement {
  &__HeaderBottom {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &__top {
      margin-bottom: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__datesTitle {
      color: #323246;
      font-weight: 400;
      font-size: 16px;
      margin-inline-end: 8px;
    }

    &__amountsTitle {
      font-weight: 400;
      font-size: 13px;
      color: #4e5d78;
    }

    &__download {
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      &__title {
        font-weight: 400;
        font-size: 13px;
        color: #4e5d78;
        margin-inline-start: 5px;
      }
    }
  }

  &__activitiesTitle {
    font-weight: 600;
    font-size: 16px;
    color: #323246;
    margin-bottom: 16px;
  }

  &__invoice {
    cursor: pointer;
    background: #ffffff;
    border-radius: 10px;
    padding: 17px 8px;
    margin-bottom: 16px;
    width: 100%;

    &__content {
      display: flex;
      align-items: center;

      &__left {
        margin-inline-end: 12px;
      }

      &__right {
        width: 100%;
      }

      &__detailsItem {
        color: #9ca3af;
        font-weight: 400;
        font-size: 12px;

        &--green {
          color: rgba(94, 170, 111, 1);
        }

        &--yellow {
          color: rgba(252, 179, 92, 1);
        }

        &--red {
          color: rgba(234, 111, 126, 1);
        }
      }

      &__detailsItem:not(:last-child) {
        margin-inline-end: 4px;
      }

      &__titles {
        display: flex;
        justify-content: space-between;
      }

      &__title {
        color: #323246;
        font-weight: 600;
        font-size: 14px;
      }

      &__paymentStatus {
        &--unpaid {
          color: #fcb35c;
        }

        &--processing {
          color: #7094f7;
        }

        &--paid {
          color: #5eaa6f;
        }
      }
    }
  }

  &__emptyList {
    color: $grey;
    margin-top: 150px;
    text-align: center;
  }
}
