@import '../../../../../../../theme/palette.scss';
@import '../../../../../../../theme/mixins.scss';

.ScheduleColumn {
  width: 11.4%;
  min-width: 143px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px;
}
