@import '../../../../theme/palette.scss';
@import '../../../../theme/mixins.scss';

.AttendanceCard {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }
  &__data {
    display: flex;
    flex: 1;
    align-items: center;
    .AttendanceCard__avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-size: cover;
      margin-inline-end: 16px;
    }
    .AttendanceCard__basic {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      h4 {
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $darkGrey;
      }
      .AttendanceCard__time {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $purple;
        margin: 0;
        background-color: $white;
        &[disabled] {
          color: $lightGrey;
        }
        p {
          text-align: start;
          margin-bottom: 0;
        }
      }
    }
  }
  &__attendance {
    display: flex;
    align-items: center;
    button {
      width: 28px;
      height: 28px;
      background-color: $white;
      outline: none;
      border: 1px solid $lightGrey;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      transition: 0.5s all;
      &:last-child {
        margin-inline-start: 19px;
      }
      &.active {
        transition: 0.5s all;
        border: 1px solid $lightGreen;
        background-color: $lightGreen;
        &.cross {
          border: 1px solid $lightRed;
          background-color: $lightRed;
        }
      }
    }

    &__title {
      color: #ea6f7e;
      font-weight: 400;
      font-size: 13px;
      margin-inline-end: 4px;

      &--attended {
        color: #5eaa6f;
      }
    }
  }

  &__timeForm {
    &__container {
      display: flex;
    }
    &__inputs {
      display: flex;
      width: 67%;
      border: 1px solid #8ecd9c;
      border-radius: 5px;
      padding-top: 8px;
      padding-bottom: 6px;
      cursor: pointer;
    }

    &__input {
      width: 100%;

      .TimeInput__container {
        border-width: 0;
      }
    }

    &__total {
      display: flex;
      flex-direction: column;

      &__title {
        font-weight: 400;
        font-size: 13px;
        color: #9e9eb8;
      }

      &__value {
        font-weight: 400;
        font-size: 16px;
      }
    }

    &__total {
      width: 33%;
      padding-top: 8px;
      padding-bottom: 6px;
      padding-inline-start: 8px;
      padding-inline-end: 0px;
      background: rgba(142, 205, 156, 0.2);
      border-radius: 0 5px 5px 0;

      &__value {
        color: #7094f7;
      }
    }
  }

  &__cancelForm {
    &__select {
      border: 1px solid #f88c99;
      border-radius: 5px;
      outline: none;

      & select {
        border: none;
      }
    }
  }

  &__error {
    font-size: 14px;
    color: darkred;
  }

  &__warning {
    font-size: 14px;
    color: darkorange;
  }
}
