@import '../../../../../theme/palette.scss';
@import '../../../../../theme/mixins.scss';

.ApplicantsSection {
  &__headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__titleContainer {
    display: flex;
  }
  &__title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-inline-start: 8px;
    margin-bottom: 0;
  }
  &__sorting {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  &__sortBy {
    color: $lightGrey;
    margin-inline-end: 5px;
    margin-bottom: 0;
    font-family: Source Sans Pro;
    font-weight: 600;
  }
  &__sortingDropDown {
    background-color: $background;
    border: none;
    font-family: Source Sans Pro;
    -webkit-appearance: none;
    appearance: none;
    color: $darkGrey;
    margin-inline-end: 3px;
    font-weight: 600;
    &:focus {
      outline: none;
    }
  }
  &__seeMore {
    @include secondaryGreyButton;
    background-color: rgba(119, 119, 139, 0.1);
    width: 100%;
    height: 38px;
    margin-bottom: 19px;
    text-transform: uppercase;
  }
}
