@import '../../../../../theme/palette.scss';

.LinkItem {
  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 25px;
    transition: padding 0.3s;
    &.active {
      background-color: $veryLightGrey;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      color: $lightGrey;
      white-space: nowrap;
      &.active {
        color: $darkGrey;
      }
    }
  }
}
