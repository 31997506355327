@import '../../../../theme/palette.scss';

.OrderSummaryCard {
  background-color: $white;
  border-radius: 10px;
  padding: 25px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;

  p {
    margin-bottom: 0;
  }

  &__column {
    border-bottom: 1px solid $grey;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    &__completeCompanyDetails {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 7px;
      background-color: $orangeLightest;
      border-radius: 12px;

      &__description {
        color: $darkGrey;
        font-size: 13px;
        line-height: 16px;
      }

      &__link {
        color: $orange;
        font-size: 13px;
        line-height: 16px;
        margin-top: 0;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey;
    padding-top: 14px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 20px;

    &.withWidget {
      border-bottom: none;
      padding-bottom: 5px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom-width: 0;
      padding-bottom: 0;
    }
  }

  &__label {
    color: $lightGrey;
  }

  &__data {
    color: $darkGrey;
    text-align: end;

    &__label {
      color: #7094f7;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
