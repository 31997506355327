@import '../../theme/palette.scss';

.PageStepper {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &.justifySpaceBetween {
    justify-content: space-between;
  }
  &.justifyEnd {
    justify-content: flex-end;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__label {
      font-family: 'Gochi Hand', cursive;
      font-size: 18px;
      color: $primary;
      cursor: pointer;
    }
    &__nextImg {
      margin-inline-start: 6px;
      width: 19.58px;
      height: 16.55px;
      cursor: pointer;
    }
    &__backImg {
      margin-inline-end: 6px;
      width: 19.58px;
      height: 16.55px;
      cursor: pointer;
    }
    &__checkmarkImg {
      margin-inline-start: 6px;
      width: 19.11px;
      height: 18.25px;
      cursor: pointer;
    }
  }
}
