.PasswordInput {
  &__img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__hints {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__item {
      margin-top: 10px;
      margin-inline-end: 10px;
      padding: 0 11px;
      height: 25.74px;
      border-radius: 100px;
      background-color: rgba(156, 165, 178, 0.1);
      display: flex;
      flex-direction: row;
      align-items: center;

      &.active {
        background-color: rgba(50, 185, 143, 0.1);
      }

      &__checkmark {
        width: 0;
        height: 7.1px;
        margin-inline-end: 3.5px;
        transition: width 0.3s;

        &.visible {
          width: 9.2px;
        }
      }

      &__label {
        color: rgba(158, 158, 184, 1);

        &.active {
          color: rgba(50, 185, 143, 1);
        }
      }
    }
  }
}
