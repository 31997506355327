@import '../../theme/palette.scss';

.IconButtonLink {
  height: 48px;
  width: 51%;
  max-width: 225px;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary;
  &__icon {
    width: 15.66px;
    height: 18px;
  }
  &__label {
    color: $primary;
    font-size: 16px;
    font-weight: 400;
  }
}
