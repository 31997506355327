@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.OrderConfirmed {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__innerContainer {
    @include fullScreen;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    padding: 0 14px;
    &__img {
      height: 50%;
    }
    &__title {
      color: $darkGrey;
      font-size: 25px;
      font-weight: 700;
      font-family: Roboto Slab;
    }
    &__description {
      color: $lighterGrey;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }
    &__closeButton {
      width: 70%;
      background-color: $primary;
      color: $white;
      margin: 0 auto;
    }
  }
}
