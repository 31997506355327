@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.JobDraftRequirements {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-inline: 1px;

  &__searchContainer {
    margin-top: -40px;
  }

  &__list {
    flex: 1;
    border: 1px solid $grey;
    border-radius: 10px;
    padding-top: 9px;
    padding-bottom: 2px;
    padding-inline-start: 16px;
    padding-inline-end: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 11px 0;
      cursor: pointer;

      &__label {
        flex: 1;
        font-size: 16px;
        color: $darkGrey;
        font-weight: 400;
      }
    }
  }

  &__addRequirementButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    width: 75% !important;
    margin: 0 auto;

    &__img {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }

    &__title {
      font-size: 16px;
    }
  }

  &__modal {
    @media (min-width: $minDesktopWidth) {
      width: 30% !important;
    }

    .AddRequirement {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 0px;
      padding-inline-start: 30px;
      padding-inline-end: 30px;
    }
  }
}
