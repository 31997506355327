@import '../../../../theme/palette.scss';

.Days {
  text-align: center;
  .DayItem {
    background-color: $white;
    border: 1px solid $grey;
    &.active {
      background-color: $primary;
      border: 1px solid $primary;
    }
  }
  &__selectionLabel {
    font-size: 16px;
    font-weight: 400;
    color: $lightGrey;
  }
}
