.SelectCategory {
  &__headerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    align-self: center;
  }

  &__title {
    font-family: Roboto Slab;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #323246;
    margin: 0;
    text-align: center;
    flex: 1;
  }
}
