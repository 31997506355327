@import '../../../../../theme/palette.scss';
@import '../../../../../theme/mixins.scss';

.RateCard {
  border-bottom: 1px solid rgba($color: $grey, $alpha: 0.5);
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__leftContainer {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      min-width: 0;
      @include ant-image-small-mask;
      &__photo {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      &__name {
        color: $darkGrey;
        font-size: 16px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__qualitiesContainer {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    /* Hide scrollbar but keep scroll functionality */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__textarea {
    width: 100%;
    color: $darkGrey;
    border-radius: 10px;
    border-color: $grey;
    padding: 10px 15px 10px 15px;
    min-height: 75px;
  }
}
