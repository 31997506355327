@import '../../../../../../theme/global-variables.scss';

.BankAccountDetails {
  &__title-container {
    display: flex;
    margin-bottom: 14px;

    & span {
      color: #323246;
      font-weight: 700;
      font-size: 16px;
      margin-inline-start: 5px;
    }
  }

  &__values {
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 18px 25px 22px;
    margin-bottom: 25px;

    &__valueContainer {
      display: flex;
      justify-content: space-between;

      &__valueBox {
        display: flex;
        align-items: center;
      }

      &__key {
        color: #9e9eb8;
        font-weight: 400;
        font-size: 16px;
      }

      &__value {
        color: #323246;
        font-weight: 400;
        font-size: 16px;

        &__icon {
          margin-inline-start: 5px;
          cursor: pointer;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(203, 203, 226, 0.5);
        padding-bottom: 16px;
      }

      &:not(:first-child) {
        padding-top: 14px;
      }
    }
  }
}
