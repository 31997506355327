.AttendanceManagerList {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.AttendanceManagerRow {
  display: flex;
  padding: 20px 100px 0 20px;
  margin-bottom: 20px;
  border-top: 1px solid gray;
  &__name {
  }
  &__main {
    flex: 1;
    margin: 0 10px;
  }
}
