.QuestionList {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  column-gap: 16px;
  overflow-x: scroll;

  /* Hide scrollbar but keep functionality */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
