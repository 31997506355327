@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.ShiftDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $background;
  position: relative;
  .backIcon {
    width: 25px;
    position: absolute;
    top: 23px;
    inset-inline-start: 15px;
  }
  &__title {
    @include title;
    text-align: center;
    margin-top: 17px;
    margin-bottom: 25px;
  }
  &__shiftContainer {
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    border-radius: 10px;
    margin-bottom: 11px;
    max-width: 600px;
  }
  &__shiftBasic {
    padding: 0 2px;
  }
  &__attendanceList {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    padding: 0 18px;
    .ShiftDetails__isAttended {
      display: flex;
      justify-content: flex-end;
      padding-inline-end: 5px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        color: $mediumGrey;
      }
    }
  }
  .ShiftDetails__confirm {
    display: flex;
    justify-content: space-between;
    padding: 40px 23px 33px;
    .ShiftDetails__attendanceCount {
      display: flex;
      flex-direction: column;
      & > div {
        color: $mediumGrey;
        img {
          margin-inline-end: 10px;
          width: 10px;
        }
        &:last-child {
          margin-top: 6px;
        }
      }
    }
    .ShiftDetails__confirmButton {
      @include primaryButton;
      width: 168px;
      height: 48px;
    }
  }
}
