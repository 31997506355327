@import '../../theme/palette.scss';

.Checkbox {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey;
  &__image {
    width: 11.98px;
    height: 8.26px;
  }
  &.square {
    border-radius: 3px;
  }
  &.circle {
    border-radius: 10px;
  }
  &.circle.active {
    background-color: $white;
    border: 1px solid $primary;
  }
  &.square.active {
    background-color: $primary;
    border: 1px solid $primary;
  }
  &__centerCircle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $primary;
  }
}
