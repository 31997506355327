@import './theme/palette.scss';
@import './theme/global-variables.scss';

html,
body,
#root {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  background-color: #ffffff;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body[dir='rtl'] .rtl-x-flipped {
  // flips element horizontally
  transform: scaleX(-1);
}
body[dir='rtl'] input {
  &::-webkit-input-placeholder {
    direction: rtl;
  }
  &::-moz-placeholder {
    direction: rtl;
  }
  &:-ms-input-placeholder {
    direction: rtl;
  }
  &::placeholder {
    direction: rtl;
  }
  &:placeholder-shown[type='email'],
  &:placeholder-shown[type='tel'],
  &:placeholder-shown[type='number'],
  &:placeholder-shown[type='url'] {
    direction: ltr;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.row {
  display: flex;
  flex: 1;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceAround {
  justify-content: space-around;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex1 {
  flex: 1;
}

button {
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

input,
textarea {
  background-color: transparent;

  &:focus {
    outline: none !important;
    border: 1px solid $primary;
    box-shadow: 0 0 3px $primary;
  }
}

textarea {
  resize: none;
}

select {
  &:focus {
    outline: none !important;
    border: 1px solid $primary;
    box-shadow: 0 0 3px $primary;
  }
}

.desktop-only {
  display: none;
  @media (min-width: $minDesktopWidth) {
    display: inherit;
  }
}

.mobile-only {
  display: none;
  @media (max-width: $maxMobileWidth) {
    display: inherit;
  }
}
