@import '../../theme/palette.scss';

.Pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  &__left {
    display: flex;
    align-items: center;
    cursor: pointer;

    & img {
      margin-inline-end: 5px;
    }

    & span {
      color: #7767e4;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__middle {
    &__pages {
      color: #9e9eb8;
      font-size: 14px;
      font-weight: 400;

      &__current {
        color: $mediumGrey;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    cursor: pointer;

    & span {
      margin-inline-end: 5px;
      color: #7767e4;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
