@import '../../theme/palette.scss';
@import '../../theme/global-variables.scss';

.AppLayout {
  background-color: $background;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  @media (min-width: $minDesktopWidth) {
    flex-direction: row;
  }
  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    overflow-y: scroll;
    & > *:last-of-type {
      width: 100%;
      max-width: 600px;
      align-self: center;
    }
  }
  &__desktop {
    display: none;
    @media (min-width: $minDesktopWidth) {
      display: block;
    }
  }
}
#modals {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  &:not(:empty) {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
}
