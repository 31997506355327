.tabs {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px;
    background: #f9fafb;
    border-radius: 12px;
    width: 100%;

    &__button {
      padding: 10px 24px;
      background: #f9fafb;
      color: #707784;
      font-size: 16px;
      font-weight: 400;
      border-radius: 8px;
      width: 100%;
      position: relative;

      &--active,
      &:hover {
        color: #7767e4;
        font-weight: 600;
        background: #ffffff;
        box-shadow:
          0 0 1px rgba(113, 128, 150, 0.04),
          0 4px 8px rgba(113, 128, 150, 0.08);
      }
    }

    &__updates-indicator {
      background: #fcb35c;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
}
