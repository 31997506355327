@import '../../theme/palette.scss';

.Stepper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 24px;

  &__leftContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__label {
      margin-top: 8px;
      font-weight: 400;
      font-size: 13px;
      color: $lightGrey;
    }

    &__progressBar {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-self: stretch;
      height: 6px;
      border-radius: 6px;
      background-color: #e0dfe1;

      &__step {
        height: 100%;
        background-color: $primary;

        &.first {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &.last {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  &__rightContainer {
    display: flex;
    flex-direction: row;

    &__backButton {
      box-sizing: border-box;
      height: 50px !important;
      width: 50px !important;
      border-radius: 5px;
      border: 1px solid $primary;

      &__img {
        width: 8px;
        height: 14.8px;
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    &__nextButton {
      height: 50px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      background-color: $primary;

      &__label {
        font-weight: 600;
        font-size: 16px;
        color: $white;
      }

      &__img {
        width: 8px;
        height: 14.8px;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
