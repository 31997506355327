@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.JobDraftAccreditation {
  display: flex;
  flex-direction: column;
  &__description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 13px;
    color: $orange;
    line-height: 15.34px;
    margin-bottom: 63.5px;
  }
  &__innerContainer {
    padding: 0 19px;
    display: flex;
    flex-direction: column;
    &__button {
      width: 100% !important;
      height: 56px !important;
      border-color: $grey !important;
      color: $darkGrey;
      &.active {
        background-color: $primary;
        color: $white;
      }
      &.error {
        border-color: $error !important;
        color: $error;
      }
    }
  }
}
