.Button {
  &__secondary {
    background: #fff;
    border: 1px solid #7767e4;
    border-radius: 5px;
    padding: 12px 35px;
    font-weight: 400;
    font-size: 16px;
    color: #7767e4;
  }
}
