@import '../../../theme/palette.scss';

.InstallAppBanner {
  width: 100%;

  &__top {
    height: 70px;
    background: rgba(145, 147, 226, 0.15);
    width: 100%;
    position: relative;

    & img {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__logo {
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__description {
      margin-top: 50px;
      font-size: 16px;
      font-weight: 400;
      color: #323246;
      text-align: center;

      &__ogram {
        font-size: 16px;
        font-weight: 400;
        color: #7767e4;
      }
    }

    &__panelAndroid {
      margin-top: 10px;
      margin-bottom: 20px;
      background: rgba(145, 147, 226, 0.15);
      padding: 5px 10px;
      border-radius: 12px;

      &__tap,
      &__add {
        font-size: 16px;
        font-weight: 600;
        color: #323246;
      }

      &__then {
        font-size: 16px;
        font-weight: 400;
        color: $mediumGrey;
      }

      &__phone {
        margin-inline-start: 7px;
        margin-inline-end: 7px;
      }
    }

    &__panelIos {
      margin-top: 10px;
      margin-bottom: 20px;
      background: rgba(145, 147, 226, 0.15);
      padding: 5px 10px;
      border-radius: 12px;
      display: flex;
      align-items: center;

      &__tap,
      &__add {
        font-size: 16px;
        font-weight: 600;
        color: #323246;
      }

      &__then {
        font-size: 16px;
        font-weight: 400;
        color: $mediumGrey;
      }

      &__phone,
      &__burger {
        margin-inline-start: 7px;
        margin-inline-end: 7px;
      }
    }
  }
}
