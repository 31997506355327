@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.JobDraftInvitedSPs {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-inline: 1px;

  &__searchContainer {
    margin-top: -40px;
  }

  &__list {
    flex: 1;
    border: 1px solid $grey;
    border-radius: 10px;
    padding-top: 9px;
    padding-bottom: 2px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 8px;
      padding: 8px;
      margin-bottom: 8px;

      &--disabled {
        background: #f5f5fb;
      }

      &__title {
        font-size: 16px;
        font-weight: 700;
        color: #323246;
        line-height: 18px;
        margin-right: 5px;
      }

      &__titleContainer {
        display: flex;
        align-items: center;
      }

      &__subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #6e6e87;
        margin-bottom: 5px;
      }

      &__body {
        display: flex;
        align-items: center;

        &__image {
          border-radius: 100%;
          width: 72px;
          height: 72px;
          margin-right: 12px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &__addRequirementButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    width: 75% !important;
    margin: 0 auto;

    &__img {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }

    &__title {
      font-size: 16px;
    }
  }

  &__modal {
    @media (min-width: $minDesktopWidth) {
      width: 30% !important;
    }

    .AddRequirement {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 0px;
      padding-inline-start: 30px;
      padding-inline-end: 30px;
    }
  }

  &__addedList {
    display: flex;
    flex-wrap: wrap;

    &__item {
      background: #f1f1f9;
      border-radius: 5px;
      padding: 5px 9px;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      & > span {
        color: #323246;
        font-size: 13px;
        font-weight: 400;
        margin-right: 10px;
      }

      & > img {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__noDataProvided {
    font-size: 16px;
    text-align: center;
    color: $lightGrey;
  }
}
