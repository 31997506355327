@import '../../../../../theme/palette.scss';

.WorkExperienceCard {
  display: flex;
  flex-direction: row;
  &__imgContainer {
    min-width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5fb;
    border-radius: 6px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title {
      font-size: 16px;
      font-weight: 600;
      color: $darkGrey;
    }
    &__company {
      font-size: 16px;
      font-weight: 400;
      color: $darkGrey;
    }
    &__location {
      font-size: 16px;
      font-weight: 400;
      color: #babed6;
    }
    &__time {
      font-size: 16px;
      font-weight: 400;
      color: #babed6;
    }
    &__description {
      font-size: 16px;
      font-weight: 400;
      color: $mediumGrey;
      margin: 0;
    }
  }
}
