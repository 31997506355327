@import '../../../../theme/palette.scss';
@import '../../../../theme/global-variables.scss';

.Card {
  cursor: pointer;
  flex: 1 0 40%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding-top: 11px;
  padding-bottom: 8px;
  padding-inline-start: 8px;
  padding-inline-end: 0;
  border-radius: 10px;
  background-color: $orange;
  margin-bottom: 15px;
  .img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-inline-end: 12px;
  }
  &__right-container {
    display: flex;
    flex-direction: column;
    .amount {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }
    .title {
      font-size: 15px;
      line-height: 18px;
      margin-top: 5px;
    }
  }
  @media (min-width: $minDesktopWidth) {
    .img {
      width: 35px;
      height: 35px;
      margin-inline-end: 20px;
    }
    &__right-container {
      .amount {
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        margin-top: 6px;
      }
      .title {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
  &:nth-child(1n) {
    margin-right: 15px;
  }
}

.LastCardSeparator {
  flex: 1 0 40%;
  display: flex;
  flex-direction: row;
  padding: 11px 0 8px 8px;
  margin-bottom: 15px;
}
