@import '../../../../theme/palette.scss';

.JobDraftDetails {
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $darkGrey;
  }
  &__row {
    display: flex;
    flex-direction: row;
  }
  &__submitButton {
    background-color: $primary;
    color: $white;
  }
  &__bottomError {
    font-size: 16px;
    font-weight: 400;
    color: $lightRed;
  }
}
