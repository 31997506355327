.QrAttendance {
  &__content {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  &__profile {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    &__image {
      width: 156px;
      height: 156px;
      border-radius: 100%;
    }
    &__name {
      color: #323246;
      font-size: 16px;
      font-weight: 700;
      margin-top: 5px;
    }
    &__id {
      color: #9e9eb8;
      font-size: 13px;
      font-weight: 400;
    }
  }
  &__order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: #f5f5fb;
    border-radius: 8px;
    &__info {
      display: flex;
      flex-direction: column;
      &__number {
        color: #9e9eb8;
        font-size: 13px;
        font-weight: 400;
      }
      &__designation {
        color: #4e5d78;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  &__stat-item {
    display: flex;
    align-items: center;
    & span {
      color: #323246;
      font-size: 14px;
      font-weight: 400;
      margin-inline-start: 5px;
    }
  }
  &__timePicker {
    height: 55px;
    width: 100%;
  }
  &__buttonSetDefault {
    position: absolute;
    top: 9px;
    right: 50px;
    padding: 6px 21px 5px 21px;
    border-radius: 4px;
    border: 1.25px solid #7767e4;
    background: transparent;
    color: #7767e4;
    font-size: 16px;
    font-weight: 600;
  }
  &__action {
    padding: 14px 0 14px 0;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background: #5eaa6f;
    border-radius: 8px;

    &--red {
      background: #ea6f7e;
    }
  }
  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 12px;
    &__back {
      display: flex;
      align-items: center;
      align-self: flex-start;
      cursor: pointer;
      & span {
        color: #9e9eb8;
        font-size: 14px;
        font-weight: 400;
        margin-inline-start: 4px;
      }
    }
    &__checkmark {
      margin-top: 18px;
      width: 40px;
      height: 40px;
    }
    &__text {
      margin-top: 16px;
      color: #6e6e87;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
    &__primaryAction {
      padding: 14px 0;
      width: 100%;
      background: #7767e4;
      border-radius: 8px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 26px;
      & span {
        margin-inline-start: 5px;
      }
    }
    &__secondaryAction {
      padding: 14px 0;
      width: 100%;
      background: transparent;
      border-radius: 8px;
      color: #9e9eb8;
      font-size: 16px;
      font-weight: 600;
      margin-top: 8px;
    }
  }
}
