@import '../../../../../theme/palette.scss';

.ResultForm {
  &__title {
    margin-top: 24px;
    font-family: Roboto Slab;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__subtitle {
    margin-top: 8px;
    margin-bottom: 12px;
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__ratingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
  }
  &__overallStarImg {
    width: 28px;
  }
  &__overallRatingLabel {
    color: $mediumGrey;
    font-family: Roboto Slab;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  &__questionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px;
  }
  &__questionRatingLabel {
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
