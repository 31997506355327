@import '../../../../../../../theme/palette.scss';

.JobField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__label {
    font-size: 16px;
    font-weight: normal;
    color: $darkGrey;
  }
}
