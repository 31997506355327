@import '../../theme/global-variables.scss';

.OgramModal {
  width: 90% !important;
  overflow: scroll;

  @media screen and (min-width: $minDesktopWidth) {
    width: 40% !important;
  }
}
