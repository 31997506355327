@import '../../../../../theme/palette.scss';

.CheifCard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: rgb(244, 250, 255);
  padding: 12px 15px 8px 12px;
  border: 1px solid rgb(112, 148, 247);
  border-radius: 8px;
  &__infoIcon {
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }
  &__text {
    margin: 0 8px 0 12px;
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    &__highlight {
      font-family: Source Sans Pro;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgb(112, 148, 247);
    }
  }
  &__crossIcon {
    margin-top: 4px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}
