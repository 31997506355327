@import '../../theme/global-variables.scss';

.WorkersList {
  max-width: 1272px !important;
  @media (max-width: $maxMobileWidth) {
    max-width: 600px !important;
    overflow-y: scroll;
  }

  &__content {
    padding: 12px;

    @media (min-width: $minDesktopWidth) {
      background: #fff;
      border-radius: 20px;
    }

    @media (max-width: $maxMobileWidth) {
      padding: 12px 0 0 0;
    }
  }

  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 16px 8px;
    border-bottom: 1px solid #f3f3f3;

    @media (max-width: $maxMobileWidth) {
      padding: 0 0 16px 0;
    }

    & > *:not(:last-child) {
      margin-inline-end: 12px;
    }

    &__total {
      display: flex;
      align-items: center;

      @media (max-width: $maxMobileWidth) {
        background: #fafbfc;
        padding: 0 12px 0 12px;
        height: 48px;
        border-radius: 8px;
      }

      &__title {
        color: #6e6e87;
        font-size: 18px;
        font-weight: 600;
        margin-inline-start: 4px;
      }
    }

    &__searchInput {
      flex: 1;

      & > input {
        background-color: #fafafd;
        box-shadow: none;
      }
    }

    &__filterButton {
      border: 1px solid #cbcbe2;
      border-radius: 8px;
      padding: 0 12px 0 12px;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      @media (max-width: $maxMobileWidth) {
        background: #fafbfc;
        border: none;
        margin-inline-end: 0 !important;
      }

      &__title {
        color: #6e6e87;
        font-size: 16px;
        font-weight: 600;
        margin-inline-start: 8px;
      }

      &__indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ea6f7e;
        border-radius: 100%;
        font-size: 13px;
        font-weight: 400;
        color: #fff;
        margin-inline-start: 8px;
        width: 24px;
        height: 24px;
      }
    }

    &__pagination {
      border: 1px solid #cbcbe2;
      border-radius: 8px;
      padding: 0 12px 0 12px;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__pageText {
        font-size: 14px;
        font-weight: 400;
        color: #6e6e87;
      }

      &__pageValue {
        padding: 0 7px;
        background: #f5f5fb;
        font-size: 14px;
        font-weight: 600;
        margin-inline-start: 8px;
        margin-inline-end: 8px;
      }

      &__pages {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-inline-start: 16px;
        margin-inline-end: 16px;
      }
    }
  }

  &__listContainer {
    padding: 12px;

    @media (max-width: $maxMobileWidth) {
      padding: 0;
    }
  }

  &__table {
    width: 100%;
    table-layout: fixed;

    & tr:nth-child(2n) {
      background: #fafafd;
    }

    & tr {
      width: 100%;
    }
    &__head {
      & td span {
        color: #6e6e87;
        font-size: 14px;
        font-weight: 400;
      }

      & > td > div {
        padding-bottom: 12px;
      }

      &__column {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > *:not(:last-child) {
          margin-inline-end: 10px;
        }
      }
    }

    &__item {
      &:not(:nth-child(2)) {
        & > td > div {
          padding-top: 8px;
        }
      }

      &__left {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__info {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &__primary {
          color: #323246;
          font-size: 14px;
          font-weight: 500;
        }

        &__secondary {
          color: #9393b0;
          font-size: 13px;
          font-weight: 400;
          margin-top: 4px;
        }

        &__image {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-inline-end: 8px;
          cursor: pointer;
        }

        &__icon {
          margin-inline-start: 16px;
          margin-inline-end: 16px;
        }

        &__textarea {
          border: 1px solid #cbcbe2;
          border-radius: 8px;
          padding: 8px;
          color: #6e6e87;
          font-size: 13px;
          font-weight: 400;
          background: #fff;
        }
      }

      &__expanded {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__item {
    margin-bottom: 12px;

    &__header {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      justify-content: space-between;
      background: #fff;
      padding: 12px 12px 0 12px;
      border-radius: 16px 16px 0 0;

      &__image {
        border-radius: 100%;
        height: 60px;
        width: 60px;
        margin-inline-end: 8px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__minor {
          color: #b0b7c3;
          font-size: 13px;
          font-weight: 400;
        }

        &__primary {
          color: #4e5d78;
          font-size: 14px;
          font-weight: 600;
        }

        &__secondary {
          color: #6e6e87;
          font-size: 14px;
          font-weight: 400;
        }
      }

      &__left {
        display: flex;
        flex-direction: row;
      }
    }

    &__body {
      &__row {
        padding: 8px 12px 8px 12px;
        display: flex;
        justify-content: space-between;
        background: #fff;

        &:first-child {
          background: #fafafd;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;

        &__minor {
          color: #9393b0;
          font-size: 13px;
          font-weight: 400;
        }

        &__primary {
          color: #323246;
          font-size: 14px;
          font-weight: 600;
        }

        & > span {
          text-align: start;
        }
      }
    }

    &__rating {
      display: flex;
      flex-direction: column;
      justify-items: center;
      @media (max-width: $maxMobileWidth) {
        align-items: center;
        padding: 8px 12px 8px 12px;
        background: #fafafd;
      }

      &__stars {
        display: flex;
        justify-content: center;
        flex-direction: row;

        & > *:not(:last-child) {
          margin-inline-end: 4px;
        }
      }

      &__info {
        display: flex;
        justify-content: center;
        margin-top: 4px;

        &__value {
          color: #6e6e87;
          font-size: 14px;
          font-weight: 400;
          margin-inline-end: 8px;
        }

        &__highlight {
          color: #7767e4;
          font-size: 13px;
          font-weight: 600;
        }

        &__cancel {
          color: #ff6d6d;
          font-size: 12px;
          font-weight: 400;
          margin-inline-start: 5px;
        }
      }
    }

    &__note {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 12px 12px 12px;
      background: #fafafd;
      border-radius: 0 0 16px 16px;

      & textarea {
        border: 1px solid #cbcbe2;
        border-radius: 8px;
        padding: 8px;
        color: #6e6e87;
        font-size: 13px;
        font-weight: 400;
        width: 100%;
        background: #fff;
      }
    }
  }
  &__emptyList {
    & > span {
      display: block;
      width: 100%;
      color: #6e6e87;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
