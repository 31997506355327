@import './palette.scss';

@mixin title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Roboto Slab', serif;
}

@mixin primaryButton {
  background-color: $primary;
  color: $white;
  border-radius: 5px;
  transition: 0.25s all;
  box-shadow:
    0px 2px 5px rgba(104, 106, 194, 0.1),
    0px 3px 10px rgba(104, 106, 194, 0.2);
  &:disabled {
    background-color: $lightGrey;
    opacity: 0.8;
    transition: 0.25s all;
  }
}

@mixin secondaryButton {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 5px;
  box-shadow:
    0px 2px 5px rgba(104, 106, 194, 0.1),
    0px 3px 10px rgba(104, 106, 194, 0.2);
}

@mixin secondaryGreyButton {
  background-color: $veryVeryLightgrey;
  color: $lightMediumGrey;
  border-radius: 5px;
}

@mixin fullScreen {
  max-width: 600px;
  margin: 0 auto;
}

@mixin truncate {
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 500px) {
    & {
      max-width: 400px;
    }
  }
}

@mixin errorMessage {
  color: $error;
  margin-top: 10px;
}

@mixin modal {
  top: 50%;
  left: 50%;
  margin-inline-end: -50%;
  border-radius: 20px;
  width: calc(100% - 30px);
  position: absolute;
  transform: translate(-50%, -50%);
  padding-inline-start: 26px;
  padding-inline-end: 26px;
  background-color: #fff;
  @media screen and (min-width: 600px) {
    & {
      width: 350px;
    }
  }
}

@mixin infoCard {
  background-color: $white;
  border-radius: 10px;
  padding: 25px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  p {
    margin-bottom: 0;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey;
    padding-top: 14px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom-width: 0;
      padding-bottom: 0;
    }
  }
  &__label {
    color: $lightGrey;
    font-size: 16px;
    margin-bottom: 0;
  }
  &__data {
    color: $darkGrey;
    font-size: 16px;
    text-align: end;
  }
}

@mixin transparentBackground {
  background-color: rgba(0, 0, 0, 0);
}

@mixin emptyState {
  text-align: center;
  color: $lightGrey;
}

@mixin ant-image-small-mask {
  & .ant-image-mask {
    border-radius: 100%;
    & .ant-image-mask-info {
      display: none;
    }
  }
}
