@import '../../../../../theme/palette.scss';
@import '../../../../../theme/global-variables.scss';

.FormStepper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 20px;
  margin-top: auto;
  &__backButton {
    box-sizing: border-box;
    height: 50px !important;
    width: 50px !important;
    border-radius: 5px;
    border: 1px solid $grey;
    &_disabled {
      opacity: 0.5;
    }
  }
  &__backImg {
    width: 8px;
    height: 14.8px;
  }
  &__nextButton {
    flex-grow: 1;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $primary;
  }
  &__nextLabel {
    font-weight: 600;
    font-size: 16px;
    color: $white;
  }
  &__acceptButton {
    width: unset;
    margin: 0;
    flex-grow: 1;
    background-color: $lighterGreen;
    border: 1px solid $lighterGreen !important;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }
  &__acceptLabel {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.16px;
    color: $white;
  }
  &__assigned {
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    align-self: stretch;
    width: unset;
    margin: 0;
    flex-grow: 1;
    background-color: $lighterGreen;
    border: 1px solid $lighterGreen !important;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.16px;
    color: $white;
  }
  &__nextApplicantButton {
    width: unset;
    margin: 0;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    flex-grow: 2;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(119, 103, 228, 0.2);
    border: none;
    @media screen and (min-width: $minDesktopWidth) {
      flex-grow: 1;
    }
  }
  &__nextApplicantLabel {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.16px;
    color: $blue;
  }
}
