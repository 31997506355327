@import '../../../../theme/mixins.scss';
@import '../../../../theme/palette.scss';
@import '../../theme/mixins.scss';

.SignUp {
  @include auth__container;

  &__logo {
    @include auth__logo;
  }

  &__title {
    @include auth__title;
  }

  &__subtitle {
    @include auth__subtitle;
  }

  &__button {
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 16px;
    background-color: $primary;
    color: $white;
  }

  &__backButton {
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 16px;
    border: none !important;
    width: unset !important;
    height: unset !important;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  .Select__input {
    background-color: $white;
  }

  &__topContainer {
    display: flex;
    justify-content: space-between;
  }

  &__checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__checkbox {
      cursor: pointer;
      margin-top: 2px;
    }

    &__container {
      font-size: 13px;
      font-weight: 400;
      color: #9ca3af;

      &__label {
        cursor: pointer;
      }
    }
  }
}
