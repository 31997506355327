@import '../../theme/palette.scss';

.Button {
  width: 100%;
  border: 1px solid black;
  height: 56px;
  border-radius: 5px;
  border-color: $primary;
  color: $primary;
  background-color: unset;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  &.disabled {
    opacity: 0.5;
  }
}
