@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.EditLocationPage {
  &__container {
    @include fullScreen;
    background-color: $white;
    border-radius: 10px;
    width: calc(100% - 30px);
    padding: 16px 13px 34px;
  }
}
