@import '../../../theme/palette.scss';

.OrderDraft {
  display: flex;
  flex-direction: column;
  flex: 1;
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__icon {
      width: 20px;
      height: 20px;
      margin-inline-end: 8px;
    }
    &__label {
      font-size: 16px;
      font-weight: 700;
      color: $darkGrey;
    }
  }
  &__addJobButton {
    width: 70% !important;
    &__img {
      width: 14px;
      height: 14px;
      margin-inline-end: 15px;
    }
    &__title {
      font-size: 16px;
      color: $primary;
    }
  }
  &__submitButton {
    background-color: $primary;
    color: $white;
    width: 80%;
    margin: 0 auto;
  }

  &__actionButton {
    &__primary {
      background: #7767e4;
      border-radius: 5px;
      padding: 12px 35px;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      margin-inline-start: 12px;
    }

    &__secondary {
      background: #fff;
      border: 1px solid #7767e4;
      border-radius: 5px;
      padding: 12px 35px;
      font-weight: 400;
      font-size: 16px;
      color: #7767e4;
    }
  }

  &__NewJobModal {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
      width: 100%;

      & > * {
        margin-bottom: 20px;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 25px;
      color: #323246;
    }
  }
}
