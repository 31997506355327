@import '../../../../../../theme/palette.scss';

.WireTransferUploadSlip {
  margin-bottom: 120px;

  &__alert {
    padding: 24px 25px 25px 13px;
    background: #ffffff;
    border-radius: 10px;

    &__title {
      font-weight: 600;
      font-size: 16px;
      color: #4e5d78;
      margin-inline-start: 9px;
    }

    &__info {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &__text {
        font-weight: 400;
        font-size: 13px;
        color: #fcb35c;
        line-height: 16px;
        margin-inline-start: 6px;
      }
    }
  }

  &__uploadContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &__title {
      font-weight: 700;
      font-size: 18px;
      color: #323246;
    }

    &__uploadButton {
      margin-top: 15px;
      background: #fafafd;
      border: 1px dashed #cbcbe2;
      border-radius: 5px;
      width: 100%;
      color: #7767e4;
      font-weight: 400;
      font-size: 16px;
      padding: 18px 83px 18px 83px;
    }

    &__info {
      margin-top: 12px;

      &__part {
        color: $mediumGrey;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }

      &__part:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    &__cancel {
      align-self: center;
      margin-top: 26px;
      font-weight: 400;
      font-size: 16px;
      color: #ea6f7e;
      cursor: pointer;
    }
  }

  &__primaryButton {
    background: #7767e4;
    border-radius: 5px;
    padding: 11px 36px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;

    &--disabled {
      background: #babed6;
    }
  }

  &__secondaryButton {
    background: #ffffff;
    border-radius: 5px;
    padding: 11px 36px;
    color: #7767e4;
    border: 1px solid #7767e4;
    font-weight: 600;
    font-size: 16px;
    margin-inline-end: 12px;
  }

  &__bankContainer {
    margin-top: 30px;
  }
}
