@import '../../../../../../../theme/palette.scss';
@import '../../../../../../../theme/mixins.scss';

.ScheduleTime {
  flex: 1;
  .TimeInput {
    height: 56px;
    max-width: 100%;
    border-color: #cbcbe2;
    &.highlighted {
      .TimeInput__container {
        border-color: #8ecd9c;
      }
    }
    &.disabled {
      .TimeInput__container {
        background-color: #f5f5fb;
        cursor: not-allowed;

        .Input__input {
          cursor: not-allowed;
        }
      }
    }
    &__toggleIcon {
      display: none;
    }
  }
}

.BulkChangePopup {
  &__container {
    min-width: 350px;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #6e6e87;
    margin-bottom: 12px;
  }

  &__controls {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__secondary {
    padding: 4px 16px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #babed6;
    font-size: 14px;
    font-weight: 400;
    color: #6e6e87;
  }

  &__primary {
    padding: 4px 16px;
    border-radius: 8px;
    background: #7767e4;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
}
