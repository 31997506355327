@import '../../../../../../theme/palette.scss';

.AttendanceCard {
  &__Toggle {
    height: 30px;
    width: 53px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--active {
      .AttendanceCard__Toggle__switch {
        inset-inline-start: 23px;
        background-color: #5eaa6f;
      }
    }
    &__background {
      height: 24px;
      width: 46px;
      border: 1px solid #cbcbe2;
      border-radius: 27px;
      transition: background-color 0.5s 0.1s;
    }
    &__switch {
      width: 24px;
      height: 24px;
      border-radius: 27px;
      background-color: #ea6f7e;
      position: absolute;
      z-index: 2;
      inset-inline-start: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition:
        inset-inline-start 0.5s,
        background-color 0.5s;
      &__label {
        font-size: 13px;
        color: $white;
        user-select: none;
      }
    }
  }
}
