.WorkCategorySkills {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &__title {
    color: #b5b5be;
    font-size: 13px;
    font-weight: 400;
  }

  &__skillsList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
  }

  &__skill {
    display: flex;
    border-radius: 10px;
    background: rgba(211, 213, 218, 0.3);
    padding: 12px 25px 5px 10px;
    max-width: 160px;
    margin-inline-end: 8px;
    margin-bottom: 8px;
  }

  &__skillLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline-end: 6px;

    & img {
      max-width: 36px;
      max-height: 36px;
    }
  }

  &__skillRating {
    display: flex;
    justify-content: left;
    background: rgba(211, 213, 218, 1);
    border-radius: 10px;
    width: 26px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-top: 5px;
  }

  &__skillRatingBox {
    width: 4px;
    height: 2px;
    background: #9393aa;
    border-radius: 10px;
    margin-inline-end: 1px;

    &--empty {
      background: #ffffff;
    }

    &:first-child {
      margin-inline-start: 1px;
    }
  }

  &__skillRight {
    display: flex;
    flex-direction: column;
  }

  &__skillLevel {
    font-size: 12px;
    font-weight: 400;
    color: #9393aa;
    margin-bottom: 5px;
  }

  &__skillName {
    font-size: 14px;
    font-weight: 400;
    color: #272755;
  }
}
