@import '../../../../../theme/palette.scss';
@import '../../../../../theme/global-variables.scss';

.DesktopHeaderBox {
  height: 52px;
  @media (max-width: $maxMobileWidth) {
    display: none;
  }

  &__container {
    position: fixed;
    width: calc(100% - 220px);
    display: flex;
    justify-content: center;
    inset-inline-start: 220px;
    top: 0;
    height: 80px;
    background: rgba(245, 245, 251, 0.95);
    z-index: 50;
  }
}

.DesktopHeader {
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $maxMobileWidth) {
    display: none;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__back {
    cursor: pointer;
  }
  &__main-controls {
    display: flex;
    justify-content: space-between;
  }
  &__main-left {
    display: flex;
    align-items: center;
  }
  &__main-right {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 36px;
    height: 30px;
    &.hidden {
      visibility: hidden;
    }
  }
  &__title {
    font-family: Roboto Slab;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: $darkGrey;
    margin: 0;
    text-align: center;
    flex: 1;
  }
  &__subTitle {
    text-align: center;
    color: $mediumGrey;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  &__main__separator {
    width: 22px;
  }
}
