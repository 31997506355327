$background: #f5f5fb;
$primary: #7f72d9;
$purple: #7094f7;
$white: #fff;
$darkGrey: #323246;
$mediumGrey: #6e6e87;
$mediumLightGrey: #9393aa;
$mediumDarkGrey: #838184;
$lightGrey: #9e9eb8;
$grey: #cbcbe2;
$lighterGrey: #c4c4c4;
$veryLightGrey: #fafafd;
$lightRed: #ea6f7e;
$lightestRed: #feedf0;
$blue: #7767e4;
$lightBlue: #9193e2;
$orange: #fcb35c;
$orangeLight: #fff4e7;
$orangeLightest: rgba(252, 179, 92, 0.2);
$pink: #f88c99;
$error: #ff6d6d;
$veryVeryLightgrey: #f1f1f5;
$lightMediumGrey: #696974;
$green: #3dd598;
$lightGreen: #5eaa6f;
$lighterGreen: #8ecd9c;
$greenLightest: #ecfbf5;
