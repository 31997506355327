@import '../../../theme/palette.scss';

.FieldError {
  width: 100%;
  &__label {
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: 400;
    color: $lightRed;
  }
}
