@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.AllJobs {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: unset !important;
  &__section-header {
    color: $mediumGrey;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
  &__job-container {
    width: 100%;
  }
  &__empty-state {
    @include emptyState;
    text-align: start;
  }
  &__empty-label {
    align-self: center;
    color: $mediumGrey;
    font-size: 16px;
    font-weight: 600;
  }
}
