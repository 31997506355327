@import '../../theme/palette.scss';

.Home {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible !important;
  &__date {
    font-weight: 400;
    font-size: 14px;
    color: $mediumGrey;
    @media (min-width: 360px) {
      font-size: 16px;
    }
  }
  &__title {
    margin: 0;
    font-family: Roboto Slab;
    font-weight: 700;
    font-size: 24px;
    @media (min-width: 360px) {
      font-size: 25px;
    }
    .highlighted {
      color: $primary;
      text-transform: capitalize;
    }
  }
  &__cards-container {
    display: flex;
    flex-wrap: wrap;
  }
}
