.BestPriceGuarantee {
  border-radius: 8px;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 60px;

  &__top {
    display: flex;
    margin-bottom: 20px;

    & img {
      margin-inline-end: 20px;
    }

    &__description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__title {
        font-size: 16px;
        font-weight: 700;
        color: #323246;
      }

      &__text {
        font-size: 16px;
        font-weight: 400;
        color: #9e9eb8;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;

    &__description {
      font-size: 16px;
      font-weight: 400;
      color: #9e9eb8;
      margin-bottom: 12px;
    }

    &__action {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #7767e4;
    }
  }
}
