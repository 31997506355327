@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.OrderRequested {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__innerContainer {
    @include fullScreen;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 14px;
    &__img {
      height: 50%;
    }
    &__title {
      color: $darkGrey;
      font-size: 25px;
      font-weight: 700;
      align-self: flex-start;
      font-family: Roboto Slab;
    }
    &__description {
      color: $lighterGrey;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }
    &__closeButton {
      width: 70%;
      background-color: $primary;
      color: $white;
      margin: 0 auto;
    }
  }

  &__horizontalStats {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__item {
      display: flex;
      align-items: center;

      &__title {
        font-weight: 700;
        font-size: 16px;
        color: #323246;
        margin-inline-start: 11px;
      }
    }
  }

  &__actionPanel {
    &__primaryButton {
      background: #7767e4;
      border-radius: 5px;
      padding: 11px 36px;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
    }

    &__secondaryButton {
      background: #ffffff;
      border-radius: 5px;
      padding: 11px 36px;
      color: #7767e4;
      border: 1px solid #7767e4;
      font-weight: 600;
      font-size: 16px;
      margin-inline-end: 12px;
    }
  }
}
