@import '../../../theme/palette.scss';
@import '../theme/mixins.scss';

.ForgotPassword {
  @include auth__container;
  &__logo {
    @include auth__logo;
  }
  &__title {
    @include auth__title;
  }
  &__subtitle {
    @include auth__subtitle;
  }
  &__button {
    @include auth__button;
  }
  &__backContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    &__backLink {
      color: $primary;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
