.InvitedSP {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;

  &--disabled {
    background: #d3d5da4d;
  }

  &__status {
    color: #ea6f7e;
    font-size: 13px;
    font-weight: 400;
    margin-inline-end: 11px;
    line-height: 18px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: #323246;
    line-height: 18px;
    margin-inline-end: 5px;
  }

  &__titleContainer {
    display: flex;
    align-items: center;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #6e6e87;
    margin-bottom: 5px;
  }

  &__body {
    display: flex;
    align-items: center;

    &__image {
      border-radius: 100%;
      width: 72px;
      height: 72px;
      margin-inline-end: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__ratingNote {
    font-size: 13px;
    font-weight: 400;
    margin-top: 8px;

    &--positive {
      color: #7094f7;
    }

    &--negative {
      color: #ea6f7e;
    }
  }
}
