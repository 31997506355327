@import '../../../../../../theme/palette.scss';
@import '../../../../../../theme/mixins.scss';
@import '../../../../../../theme/global-variables.scss';

.QuestionCreate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media screen and (min-width: $minDesktopWidth) {
    padding: 30px;
  }

  &__title {
    margin-bottom: 20px;
    font-family: Roboto Slab;
    font-size: 25px;
    line-height: 32px;
    font-weight: 700;
    color: $darkGrey;
    text-align: center;

    @media screen and (min-width: $minDesktopWidth) {
      font-size: 30px;
    }
  }

  &__input {
    width: 100%;
  }

  .Button__primary {
    width: 100%;
    margin-top: 20px;
  }
}
