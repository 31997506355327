@import '../../../../theme/palette.scss';

.Invoice {
  &__dropdownImg {
    padding: 0 10px;
  }

  &__sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__detailsImg {
      width: 20px;
      height: 20px;
    }

    &__costsImg {
      width: 19.39px;
      height: 16px;
    }

    &__breakdownImg {
      width: 20px;
      height: 20px;
    }

    &__title {
      font-size: 16px;
      color: $darkGrey;
      font-weight: 700;
    }
  }

  &__cardContainer {
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 25px 18px 25px 22px;
    border-radius: 10px;

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 14px 0 16px 0;
      border-bottom: 1px solid $grey;

      &__label {
        font-size: 16px;
        color: $lightGrey;
        font-weight: 400;
      }

      &__value {
        font-size: 16px;
        color: $darkGrey;
        font-weight: 400;

        &.highlighted {
          font-weight: 600;
        }
      }

      &.last {
        border: unset;
        padding-bottom: 0;
      }
    }
  }

  &__payButton {
    width: 90% !important;
    background-color: $primary;
    color: $white;
  }

  .Ogrammer:last-of-type {
    border-bottom: unset;
  }

  &__Payment {
    &__payButton {
      background: #7767e4;
      border-radius: 5px;
      padding: 9.5px 57px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      max-width: 210px;
      align-self: center;
      margin-top: 25px;
    }
  }

  &__orderId {
    color: #9e9eb8;
    font-size: 16px;
    font-weight: 400;

    &__separator {
      margin-right: 5px;
      &:last-child {
        display: none;
      }
    }
  }
}
