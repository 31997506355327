.StickyButton {
  &__button {
    padding: 11px 80px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    background: #7767e4;
    border-radius: 5px;
    outline: none;
  }
}
