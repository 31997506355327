@import '../../../../../theme/palette.scss';
@import '../../../../../theme/mixins.scss';

.ChiefPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-family: Roboto Slab;
    font-size: 25px;
    line-height: 32px;
    font-weight: 700;
    color: $darkGrey;
  }
  &__subtitle {
    margin-top: 12px;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: $lightGrey;
  }
  &__imageContainer {
    margin-top: 45px;
    position: relative;
    border-radius: 50%;
    background-color: #e5e5ed;
    &__avatar {
      object-fit: cover;
      object-position: center;
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    &__beacon {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 0;
      bottom: 0;
    }
  }
  &__name {
    margin-top: 12px;
    font-family: Roboto Slab;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: $darkGrey;
  }
  &__chiefLabel {
    margin-top: 8px;
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $mediumGrey;
  }
  &__instructionsTitle {
    align-self: flex-start;
    margin-top: 8px;
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $blue;
  }
  &__instructionsDescription {
    margin-top: 10px;
    align-self: flex-start;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $mediumGrey;
    &__highlight {
      font-weight: 600;
    }
  }
  .Button__primary {
    margin-top: 20px;
  }
}
