@import '../../../../../../../../../../../../theme/palette.scss';

.CheckInOutModal {
  padding: 0 23px;
  &__timeInput {
    width: 100%;
  }
  &__title {
    font-family: Roboto Slab;
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
  }
  &__confirmButton {
    color: $white;
    background-color: $primary;
  }
}
