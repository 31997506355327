@import '../../theme/palette.scss';
@import '../../theme/global-variables.scss';

.ReactModal__Overlay {
  z-index: 200;
  padding-top: 0px !important;

  @media (max-width: $maxMobileWidth) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .ReactModal__Content.Popup {
      height: 90%;
      width: 100%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      transition: transform 0.4s;
      transform: translateY(100%);
    }

    .ReactModal__Content--after-open.Popup {
      transform: translateY(0%);
    }

    .ReactModal__Content--before-close.Popup {
      transform: translateY(100%);
    }
  }

  @media (min-width: $minDesktopWidth) {
    .ReactModal__Content.Popup {
      height: 100%;
      width: 50%;
      margin-inline-start: auto;
      transition: transform 0.4s;
      transform: translateX(100%);
    }

    .ReactModal__Content--after-open.Popup {
      transform: translateX(0%);
    }

    .ReactModal__Content--before-close.Popup {
      transform: translateX(100%);
    }
  }
}

body[dir='rtl'] {
  .ReactModal__Overlay {
    @media (min-width: $minDesktopWidth) {
      .ReactModal__Content.Popup {
        transform: translateX(-100%);
      }
      .ReactModal__Content--after-open.Popup {
        transform: translateX(0%);
      }
      .ReactModal__Content--before-close.Popup {
        transform: translateX(-100%);
      }
    }
  }
}

.Popup {
  background-color: $white;
  padding-top: 13px;
  padding-bottom: 15px;
  padding-inline-start: 21px;
  padding-inline-end: 19px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* Hide scrollbar but keep scroll functionality */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    color: $darkGrey;
    line-height: 25.2px;
    margin-top: 13.5px;
    display: flex;
    align-items: center;

    & > .Label {
      margin-right: 8px;
    }

    @media (min-width: $minDesktopWidth) {
      font-size: 25px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 13px;
    color: $lightGrey;
    line-height: 15.34px;
    margin-top: 8px;
    margin-bottom: 63.5px;

    @media (min-width: $minDesktopWidth) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &__spacer {
      width: 24px;
    }

    &__topBar {
      width: 48px;
      height: 4px;
      border-radius: 8px;
      background-color: $lightGrey;
    }

    &__closeContainer {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 1px solid $lightGrey;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__img {
        width: 10.21px;
        height: 10.21px;
        margin-top: 1px;
        margin-inline-start: 1px;
      }
    }
  }

  @media (min-width: $minDesktopWidth) {
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &__spacer {
        display: none;
      }

      &__topBar {
        display: none;
      }

      &__closeContainer {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid $lightGrey;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &__img {
          width: 10.21px;
          height: 10.21px;
          margin-top: 1px;
          margin-inline-start: 1px;
        }
      }
    }
  }
}
