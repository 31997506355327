@import '../../../theme/palette.scss';

.AffiliateEarningsPage {
  &__card {
    padding: 16px;
    border-radius: 16px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--align-left {
      align-items: flex-start;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    cursor: pointer;

    &__sum {
      color: #323246;
      font-size: 25px;
      font-weight: 700;
    }

    &__period {
      color: $mediumGrey;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__months {
    width: 100%;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    &__item {
      padding: 10px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(203, 203, 226, 1);
      }

      &__left {
        color: #9e9eb8;
        font-size: 13px;
        font-weight: 400;
      }

      &__right {
        color: $mediumGrey;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
