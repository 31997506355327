@import '../../../../../theme/palette';

.JobDraftPreferences {
  display: flex;
  flex-direction: column;
  &__toggles {
    margin-top: -55px;
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0;
      &__label {
        font-size: 16px;
        font-weight: 400;
        color: $darkGrey;
      }
    }
  }
}
