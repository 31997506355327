@import '../../../../../theme/mixins.scss';

.ApplicantInfo {
  @include infoCard;
  padding: 0;
  margin-top: 25px;
  &__row {
    &:last-child {
      border-bottom-width: 1px;
      padding-bottom: 16px;
    }
  }
}
