@import '../../theme/palette.scss';

.SearchInput {
  padding: 0 1px;
  position: relative;
  &__input {
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    border-width: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-inline-start: 53px;
    padding-inline-end: 14px;
    color: $darkGrey;
    font-size: 16px;
    height: 48px;
    outline: none;
    box-shadow: #d3d1d8 0px 1px 5px 0px;
    // placeholder's color
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #babed6;
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #babed6;
    }
  }
  &__searchImg {
    position: absolute;
    width: 14px;
    height: 14px;
    inset-inline-start: 20px;
    top: 17px;
  }
}
