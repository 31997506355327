@import '../../../../../../../../../../theme/palette.scss';

.CancelJobModal {
  padding: 0 23px;

  &__title {
    font-family: Roboto Slab;
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
  }

  &__subtitle {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $lighterGrey;
  }

  &__form {
    width: 100%;
  }

  &__confirm-button {
    color: $white;
    background-color: $pink;
    border-color: $pink !important;
  }

  &__textarea-container {
    .TextInput__input {
      min-height: 75px;
    }
  }
}
