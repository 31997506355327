@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.OnboardingFinished {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__container {
    @include fullScreen;
    flex: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    padding-top: 61px;
    padding-bottom: 24px;
  }
  &__modalTitle {
    @include title;
    font-style: normal;
    font-size: 25px;
    line-height: 33px;
    margin-top: 40px;
    margin-bottom: 16px;
    text-align: center;
    color: $darkGrey;
  }
  img {
    margin-top: 10px;
    width: 218px;
  }
  p {
    text-align: center;
    color: $lighterGrey;
    margin-bottom: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    width: 300px;
  }
  &__closeButton {
    @include primaryButton;
    box-shadow: none;
    width: 71%;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
