.ConfirmationShiftTools {
  display: flex;
  justify-content: center;
  align-items: center;
  &__dropdownContainer {
    padding: 10px;
    cursor: pointer;
  }
  .DropdownMenu__item {
    padding: 0;
    .MenuItem {
      padding: 12.5px 15px;
    }
  }
}
