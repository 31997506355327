@import '../../../theme/mixins.scss';
@import '../../../theme/palette.scss';

.ShiftsList {
  background-color: $background;
  display: flex;
  flex: 1;
  flex-direction: column;
  .ShiftsList__title {
    @include title;
    text-align: center;
    margin-top: 23px;
    margin-bottom: 24px;
  }
  .ShiftsList__list {
    .ShiftsList__shiftItem {
      margin: 0 auto;
      margin-bottom: 16px;
      width: 100%;
      * {
        text-decoration: none;
      }
      &__link {
        width: 100%;
      }
    }
  }
  &__hint {
    color: $grey;
    margin-top: 150px;
    text-align: center;
  }
}

@media screen and (min-width: 600px) {
  .ShiftsList {
    &__list {
      width: 100%;
      margin: 0 auto;
      .ShiftsList__shiftItem {
        width: 100% !important;
      }
    }
  }
}
