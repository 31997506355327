@import '../../theme/palette.scss';

.ButtonLink {
  width: 100%;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    height: 56px;
    border-radius: 5px;
    border-color: $primary;
    color: $primary;
    font-size: 16px;
    font-weight: 400;
  }
}
