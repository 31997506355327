@import '../../../theme/palette.scss';
@import '../theme/mixins.scss';

.ResetPassword {
  @include auth__container;
  &__logo {
    @include auth__logo;
  }
  &__title {
    @include auth__title;
  }
  &__subtitle {
    @include auth__subtitle;
  }
  &__button {
    @include auth__button;
  }
  &__matchedText {
    font-weight: bold;
    color: $green;
  }
  &__notMatchedText {
    font-weight: bold;
    color: $lightRed;
  }
}
