@import '../../../../../theme/palette.scss';

.Submenu {
  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    background: #f88c99;
    color: #fff;
    font-size: 11px;
    margin-left: 8px;
    font-weight: 400;
  }
  &__list-header {
    justify-content: space-between;
    padding-inline-start: 30px;
    padding-inline-end: 18px;
    letter-spacing: 0.5px;
    cursor: pointer;
    span {
      font-size: 12px;
      font-weight: 600;
      color: $primary;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__horizontal-separator {
    width: '100%';
    height: 1px;
    background-color: #cbcbe2;
    opacity: 0.5;
  }
  &__list {
    margin: 0;
    padding: 0;
    animation: growDown 300ms ease-in-out forwards;
    display: none;
    &.active {
      display: block;
    }
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
}
