.ScanQRButton {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 8px;
  padding-inline-end: 12px;
  border-radius: 8px;
  border: 1.5px solid #7767e4;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;

  & span {
    color: #7767e4;
    font-size: 14px;
    font-weight: 600;
  }
}
