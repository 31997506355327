@import '../../theme/palette.scss';

.TextAlert {
  &__container {
    padding: 17px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: $mediumGrey;
  }
}
