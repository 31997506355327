@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/global-variables.scss';

.AddLocationPage {
  width: 100%;

  &__container {
    @include fullScreen;
    background-color: $white;
    border-radius: 10px;
    width: calc(100% - 30px);
    padding: 16px 13px 34px;
  }

  &__topSeparator {
    @media (max-width: $minDesktopWidth) {
      min-height: 16px;
    }
  }
}
