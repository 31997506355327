@import '../../../theme/global-variables';

.total {
  display: flex;
  align-items: center;

  @media (max-width: $maxMobileWidth) {
    background: #fafbfc;
    padding: 0 12px 0 12px;
    height: 48px;
    border-radius: 8px;
  }

  &__title {
    color: #6e6e87;
    font-size: 18px;
    font-weight: 600;
    margin-inline-start: 4px;
  }
}
