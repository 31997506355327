@import '../../../../theme/palette.scss';
@import '../../../../theme/mixins.scss';

.AutoConfirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline-start: 11px;
  padding-inline-end: 11px;
  padding-top: 20px;
  padding-bottom: 30px;

  &__modalTitle {
    @include title;
    font-style: normal;
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 16px;
    text-align: center;
    color: $darkGrey;
  }

  img {
    margin-top: 10px;
    width: 218px;
  }

  p {
    text-align: center;
    color: $lighterGrey;
    margin-bottom: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }

  &__closeButton {
    @include primaryButton;
    height: 56px;
    box-shadow: none;
    width: 263px;
  }
}
