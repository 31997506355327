@import '../../../../../theme/palette.scss';

.TopQuality {
  border-radius: 5px;
  display: flex;
  height: 25px;
  padding: 0 10px;
  color: $green;
  background: rgba(61, 213, 152, 0.1);
  &__name {
    font-size: 14px;
    margin-bottom: 0;
  }
}
