@import '../../../theme/palette.scss';
@import '../../../theme/global-variables.scss';

.ant-carousel {
  .slick-dots {
    li.slick-active {
      width: 7px;
      button {
        background: #fcb35c;
      }
    }
  }
  .slick-dots-bottom {
    bottom: -28px;
    z-index: 2;
    li {
      width: 7px;
      button {
        border-radius: 100px;
        width: 5px;
        height: 5px;
        background: #b3bac5;
      }
    }
  }
}

.CarouselSlide {
  border-radius: 10px !important;
  border: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: unset !important;
  overflow: visible;
  position: relative;
  width: 100% !important;
  height: 117px !important;
  margin: unset !important;
  margin-bottom: 40px;
  padding-inline-start: unset !important;
  padding-inline-end: unset !important;
  cursor: pointer;
  &--hire {
    background-color: rgba(231, 236, 255, 1);
  }
  &--edit-company {
    background-color: rgba(255, 244, 231, 1);
  }
  &--add-poc {
    background-color: rgba(231, 236, 255, 1);
  }
  &--scan-qr {
    background-color: #e7ecff;
  }
  &--become-affiliate {
    background-color: #e7ecff;
  }
  &__img {
    position: relative;
    width: 130px;
    height: inherit;
  }
  &__rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-inline-start: 16px;
    &__actions {
      display: flex;
      flex-direction: row;
    }
    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 22.63px;
      color: $darkGrey;
      white-space: pre;
      text-align: start;
    }
    &__button {
      margin-top: 11px;
      background-color: #7767e4;
      color: $white;
      height: 30px;
      font-size: 13px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      &--hire {
        background-color: #7767e4;
      }
      &--edit-company {
        background-color: #fcb35c;
      }
      &--add-poc {
        background-color: #7767e4;
      }
      &--scan-qr {
        background-color: #7767e4;
      }
      &--dismiss-add-poc,
      &--dismiss-scan-qr {
        background-color: transparent;
        span {
          color: #7767e4;
        }
      }
    }
  }
}
