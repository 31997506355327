.SectionTitle {
  display: flex;
  align-items: center;
  h3 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-inline-start: 8px;
    margin-bottom: 0;
  }
}
