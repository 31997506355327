@import '../../../theme/palette.scss';
@import '../../../theme/mixins.scss';

.JobCherryPicking {
  background-color: $background;
  flex: 1;
  &__container {
    @include fullScreen;
    width: 100%;
    margin: 0 auto;
  }
  &__header {
    display: flex;
    margin-top: 51px;
    margin-bottom: 38px;
  }
  &__back {
    margin-inline-end: auto;
    background-color: $background;
  }
  &__title {
    @include title;
    text-align: center;
    color: $darkGrey;
    line-height: 140%;
    margin-inline-end: auto;
    margin-bottom: 0;
  }
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
