@import '../../../../../../theme/palette.scss';

.InvoiceCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 14.5px 17px;
  border-radius: 10px;
  font-weight: unset;
  color: unset;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__dateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__ordersContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--multipleOrders {
      align-items: flex-start !important;
      & img {
        margin-top: 6px !important;
      }
    }

    & img {
      margin-top: 0;
    }

    &__orders {
      display: flex;
      flex-direction: column;
    }
    a {
      margin-top: 0;
      font-weight: 400;
      margin-right: 5px;
    }
  }

  &__numberLabel {
    font-weight: 600;
    font-size: 16px;
    color: $darkGrey;
  }

  &__statusContainer {
    padding: 0 16px;
    border-radius: 5px;

    &.success {
      background-color: $greenLightest;
    }

    &.warning {
      background-color: $orangeLightest;
    }
  }

  &__statusLabel {
    font-size: 14px;

    &.success {
      color: $green;
    }

    &.warning {
      color: $orange;
    }
  }

  &__dateLabel {
    font-size: 16px;
    color: $lightGrey;
  }

  &__timeTermLabel {
    font-size: 13px;

    &.pastDate {
      color: $error;
    }
  }

  &__amountLabel {
    font-weight: 600;
    font-size: 14px;
    color: $darkGrey;
  }
}
