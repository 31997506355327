@import '../../../../../theme/palette';

.SameTime {
  display: flex;
  flex-direction: column;
  &__innerContainer {
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    height: 56px;
  }

  &__timePicker {
    height: 56px;
    &.wide {
      & summary {
        padding: 16px 12px 16px 8px;
      }
      &.error {
        .TimeInput__container {
          border: 1px solid $error !important;
          .TimeInput__label {
            color: $error;
          }
        }
      }
    }
  }
}
