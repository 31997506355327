@import '../../../../../theme/palette.scss';

.JobCard {
  background-color: $white;
  padding-top: 20px;
  padding-bottom: 6px;
  padding-inline-start: 20px;
  padding-inline-end: 25px;
  border-radius: 10px;
  cursor: pointer;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__editJob {
      display: flex;
      align-items: center;
      &__title {
        color: #9e9eb8;
        font-size: 13px;
        font-weight: 400;
        margin-inline-end: 4px;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      color: $darkGrey;
    }

    &__rightContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__copyImg {
        width: 15px;
        height: 17px;
      }

      &__deleteImg {
        width: 18px;
        height: 18px;
        margin-inline-start: 18px;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;

    &--vertical {
      flex-direction: column;
      align-items: flex-start;
    }

    &__label {
      font-size: 16px;
      font-weight: 400;
      color: $lightGrey;

      &__note {
        color: #7094f7;
        font-size: 14px;
        font-weight: 400;
      }

      &--highlighted {
        color: #7767e4;
      }
    }

    &__value {
      font-size: 16px;
      font-weight: 400;
      color: $darkGrey;
      text-align: end;

      &__additional {
        color: #babed6;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__hint {
      font-size: 16px;
      font-weight: 400;
      color: #babed6;
    }
  }

  &__separator {
    height: 1px;
    background-color: $grey;
  }

  &__pricingTable {
    margin-top: 20px;

    &__content {
      width: 100%;

      & tr {
        &:nth-child(2n) {
          background: #f5f5fb;
        }

        & td {
          padding-top: 10px;
          padding-bottom: 10px;
          &:not(:first-child) {
            text-align: end;
          }

          &:first-child {
            padding-inline-start: 10px;
          }

          &:last-child {
            padding-inline-end: 10px;
          }
        }
      }
    }

    &__header {
      &__value {
        font-size: 14px;
        font-weight: 400;
        color: #9e9eb8;
      }
    }

    &__row {
      &__type {
        font-size: 16px;
        font-weight: 400;
        color: #9e9eb8;
      }

      &__value {
        font-size: 16px;
        font-weight: 400;
        color: #323246;
      }
    }
  }

  &__pricingInfo {
    &__table {
      width: 100%;

      & tr {
        & td {
          padding-top: 20px;
          padding-bottom: 10px;

          &:not(:first-child) {
            text-align: end;
          }
        }
      }

      &__title {
        font-size: 16px;
        font-weight: 600;
        color: #9e9eb8;
      }

      &__prevTotal {
        font-size: 16px;
        font-weight: 400;
        color: #9e9eb8;
        text-decoration: line-through;
      }

      &__total {
        font-size: 16px;
        font-weight: 600;
        color: #323246;
      }
    }

    &__note {
      font-size: 13px;
      font-weight: 400;
      color: #fcb35c;
    }
  }

  &__bottomButton {
    padding: 8px 24px;
    width: 100%;
    background: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #7767e4;
  }

  &__schedule {
    display: flex;
    justify-content: center;

    & button {
      width: 40%;

      &:first-child {
        margin-inline-end: 10px;
      }
    }
  }
}
