@import '../../theme/global-variables.scss';

.Calendar {
  &__cancelButton {
    padding: 10px 60px;
    background: #ffffff;
    border-radius: 8px;
    color: #475569;
    font-weight: 600;
    font-size: 15px;
    margin-inline-end: 8px;
    border: 1px solid #94a3b8;
  }

  &__setButton {
    padding: 10px 60px;
    background: #7767e4;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    font-size: 15px;
  }

  &__bottomActions {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 12px 24px;
  }

  &__topActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;

    &__monthSelector {
      margin-inline-end: 8px;
    }
  }
}

.RangerCalendarModal {
  width: 400px !important;
  overflow: hidden !important;

  @media (max-width: 400px) {
    width: 100% !important;
  }
}

.react-calendar {
  width: 100% !important;
  border: none !important;

  &__month-view {
    &__days {
      &__day {
        font-weight: 400 !important;
        font-size: 15px !important;
        color: #000000 !important;
      }
    }
  }
}

abbr[title] {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: #94a3b8;
}
