@import '../../../../../../../../../../../../theme/palette.scss';

.ExtendShiftModal {
  padding: 0 23px;

  &__title {
    font-family: Roboto Slab;
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
  }

  &__confirm-button {
    color: $white;
    background-color: $primary;
  }
}
