.CardPaymentStatus {
  display: flex;
  flex-direction: column;
  margin-top: 70px;

  &__content {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    padding: 56px 22px 24px 22px;
    align-items: center;
  }

  &__title {
    color: #323246;
    font-weight: 700;
    font-size: 25px;
    margin-top: 25px;
  }

  &__subtitle {
    color: #9ca3af;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 8px;

    &__email {
      color: rgba(78, 93, 120, 1);
    }
  }

  &__data {
    width: 100%;
    margin-top: 25px;
  }

  &__dataItem {
    display: flex;
    justify-content: space-between;

    &__key {
      color: #9e9eb8;
      font-weight: 400;
      font-size: 16px;
    }

    &__value {
      color: #323246;
      font-weight: 400;
      font-size: 16px;

      &--green {
        color: #5eaa6f;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(203, 203, 226, 0.5);
      padding-bottom: 16px;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  &__goToPayments {
    padding: 17px 79px;
    background: #7767e4;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    margin-top: 32px;
  }
}
