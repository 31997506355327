@import '../../../../../theme/palette';
@import '../../../../../theme/global-variables';

.JobDraftTime {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;

  &__inputWrapper {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    .TimeInput__container {
      height: 56px;
    }
  }

  &__selectScheduleLabel {
    font-size: 16px;
    text-align: center;
    color: $lightGrey;
  }

  &__selectionCount {
    font-size: 16px;
    text-align: center;
    color: $lightGrey;
    margin-top: -30px;
  }

  &__obligatoryDates {
    font-size: 16px;
    color: $lightGrey;
    margin-top: -20px;
  }

  &__dates {
    border: 1px solid $grey;
    border-radius: 10px;
    padding: 13px 11px;
    overflow-y: scroll;
    flex-grow: 1;

    &--horizontally {
      display: flex;
      justify-content: flex-start;
    }

    &::-webkit-scrollbar {
      display: block;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-right: none;
      border-left: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
      background: transparent;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 7px;
      column-gap: 10px;
      margin-inline-end: 5px;

      &__day {
        min-width: 75px;
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5px;
        border-radius: 5px;
        text-align: center;
        background-color: #f5f5fb;
        user-select: none;
        cursor: pointer;

        &.active {
          background-color: $primary;
        }

        &__label {
          font-size: 16px;
          line-height: 20.11px;
          color: $darkGrey;
          text-transform: uppercase;

          &.active {
            color: $white;
          }
        }
      }

      &__input {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 1;
      }
    }
  }
}
