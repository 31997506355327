@import '../../../../../theme//palette.scss';

.NotificationItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;

  &:active {
    background-color: $veryLightGrey;
  }

  &__left {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(145, 147, 226, 0.15);
  }

  &__center {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__title {
      font-size: 16px;
      font-weight: 700;
      color: $darkGrey;
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $mediumGrey;

      & a {
        color: #1890ff !important;
        margin-top: 0 !important;
      }
    }
  }

  &__right {
    max-width: 25%;
    text-align: end;

    &__time-label {
      font-size: 13px;
      font-weight: 400;
      color: $lightGrey;
    }
  }
}
