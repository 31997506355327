@import '../../../../../theme/palette';

.JobDraftField {
  width: 100%;
  height: 55px;
  border: 1px solid $grey;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-inline-start: 14px;
  padding-inline-end: 7px;
  margin-bottom: 16.5px;
  margin-top: 0;
  cursor: pointer;
  position: relative;
  &__label {
    font-weight: 400;
    font-size: 16px;
    color: $darkGrey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__placeholder {
    font-weight: 400;
    font-size: 16px;
    color: $lightGrey;
    &-minimized {
      font-size: 12px;
      font-weight: 400;
      color: $lightGrey;
    }
  }
  &__errorLabel {
    position: absolute;
    bottom: -18px;
    font-size: 12px;
    color: $lightRed;
  }
}
