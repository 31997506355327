@import '../../../../../theme/mixins';
@import '../../../../../theme/palette';
@import '../../../theme/mixins';

.SignUp {
  @include auth__container;

  &__logo {
    @include auth__logo;
  }

  &__pageTitleContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    &__title {
      font-family: Roboto Slab;
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      color: #323246;
    }
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    color: $darkGrey;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16.34px;
    color: #9e9eb8;
  }

  &__button {
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 16px;
    background-color: $primary;
    color: $white;
  }

  &__backButton {
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 16px;
    border: none !important;
    width: unset !important;
    height: unset !important;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  .Select__input {
    background-color: $white;
  }

  &__already_registered_container {
    margin-top: 5px;

    &__text {
      font-weight: 400;
      font-size: 14px;
      color: $mediumGrey;
    }

    &__link {
      font-weight: 400;
      font-size: 14px;
      color: #7f72d9;
      margin: 0;
    }
  }

  &__checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__checkbox {
      cursor: pointer;
      margin-top: 2px;
    }

    &__labelContainer {
      font-size: 13px;
      font-weight: 400;
      color: #9ca3af;
    }
  }
}
