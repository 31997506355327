@import '../../../../theme/palette.scss';

.PaymentList {
  overflow-y: scroll;
  max-width: 100% !important;

  &__container {
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__emptyList {
    color: $grey;
    margin-top: 150px;
    text-align: center;
  }
}

.PaymentItem {
  cursor: pointer;
  background: #ffffff;
  border-radius: 10px;
  padding: 17px 8px;
  margin-bottom: 16px;
  width: 100%;

  &__content {
    display: flex;
    align-items: flex-start;

    &__left {
      margin-inline-end: 12px;
      margin-top: 5px;
    }

    &__right {
      width: 100%;
    }

    &__detailsItem {
      color: #9ca3af;
      font-weight: 400;
      font-size: 12px;
    }

    &__detailsItem:not(:last-child) {
      margin-inline-end: 4px;
    }

    &__titles {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      color: #323246;
      font-weight: 600;
      font-size: 14px;
    }

    &__paymentStatus {
      &--unpaid {
        color: #fcb35c;
      }

      &--processing {
        color: #7094f7;
      }

      &--paid {
        color: #5eaa6f;
      }

      &--overdue {
        color: rgba(234, 111, 126, 1);
      }
    }

    &__payment-method-container {
      margin-top: 4px;
    }

    &__payment-method {
      color: #9ca3af;
      font-weight: 600;
      font-size: 12px;
    }
  }

  &__actions {
    margin-top: 18px;
    display: flex;
    justify-content: center;

    &__payButton {
      padding: 5.5px 40.5px;
      background: #7767e4;
      border-radius: 5px;
      outline: none;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
