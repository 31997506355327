@import '../../theme/palette.scss';
@import '../../theme/global-variables.scss';

.DropdownSelect {
  position: relative;
  width: 100%;
  display: inline-block;

  > img {
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    position: absolute;
    min-width: 55vw;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0px 8px 16px 0px #c8cce5;
    padding: 5px 0;
    z-index: 1;
    width: 100%;
    right: 0;

    @media screen and (min-width: $minDesktopWidth) {
      min-width: 200px;
    }
  }

  &__item {
    cursor: pointer;
    padding: 12.5px 15px;

    &:hover {
      background-color: $veryLightGrey;
    }
  }

  &__selector {
    display: flex;
    border-radius: 8px;
    border: 1px solid #cbcbe2;
    min-width: 140px;
    width: 100%;
    padding: 0 10px;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    & img {
      width: 24px;
      height: 45px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      &__title {
        color: #9e9eb8;
        font-size: 13px;
        font-weight: 400;
        &--no-value {
          color: $mediumGrey;
          font-size: 16px;
          font-weight: 600;
        }
      }
      &__value {
        color: #323246;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
