@import '../../../theme/palette.scss';
@import '../../../theme/global-variables.scss';

.ReactModal__Overlay {
  .ReactModal__Content.NotificationsBar {
    height: 100%;
    width: 90%;
    margin-inline-start: auto;
    transition: transform 0.4s;
    transform: translateX(100%);

    @media (min-width: $minDesktopWidth) {
      width: 50%;
    }
  }

  .ReactModal__Content--after-open.NotificationsBar {
    transform: translateX(0%);
  }

  .ReactModal__Content--before-close.NotificationsBar {
    transform: translateX(100%);
  }
}

.NotificationsBar {
  padding: 25px 20px 15px 20px;

  @media (min-width: $minDesktopWidth) {
    padding: 33px 30px 40px 50px;
  }

  &__bell-container {
    cursor: pointer;
    position: relative;

    &__bell-indicator {
      position: absolute;
      top: -2px;
      right: 2px;
    }
  }

  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  /* Hide scrollbar but keep scroll functionality */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 16px;
      font-weight: 700;
      color: $darkGrey;

      &__amount {
        font-size: 16px;
        font-weight: 400;
        color: $darkGrey;
      }
    }

    &__close-img {
      cursor: pointer;
    }
  }

  &__loadMoreButton {
    text-align: center;

    &__label {
      font-size: 16px;
      font-weight: 400;
      color: #6051bb;
      cursor: pointer;
    }
  }

  &__noNotifications {
    font-size: 16px;
    font-weight: 400;
    color: $darkGrey;
    text-align: center;
  }
}
