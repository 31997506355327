@import '../../../../theme/palette.scss';

.ResendOnboardingEmail {
  padding: 20px;
  text-align: center;

  &__title {
    font-size: 17px;
    font-weight: 500;
    color: $darkGrey;
    white-space: pre;
  }

  &__successLabel {
    font-size: 14px;
    color: $lightGreen;
    text-align: center;
  }
}
