@import '../../../../theme/palette.scss';
@import '../../../../theme/mixins.scss';

.OrderSummaryJobsCard {
  background-color: $white;
  border-radius: 10px;
  padding: 25px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  p {
    margin-bottom: 0;
  }
  &__name {
    font-family: Source Sans Pro;
    font-style: normal;
    display: flex;
    h3 {
      font-weight: bold;
      color: $darkGrey;
      letter-spacing: -0.01em;
      font-size: 16px;
      line-height: 20px;
      @include truncate;
    }
    span {
      font-weight: normal;
      color: $mediumGrey;
      margin-inline-start: 4px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey;
    padding-top: 14px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom-width: 0;
      padding-bottom: 0;
    }
    &--vertical {
      flex-direction: column;
    }
  }
  &__label {
    color: $lightGrey;
  }
  &__data {
    color: $darkGrey;
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #babed6;
      margin-inline-start: 4px;
      margin-inline-end: 6px;
    }
  }
  &__reviewContainer {
    border-radius: 5px;
    padding: 0 7.5px;
    height: 20px;
    background-color: rgba(71, 117, 245, 0.1);
    &__label {
      color: #7094f7;
      font-size: 13px;
    }
  }
}
