@import '../../theme/palette.scss';

.Multiselect {
  position: relative;

  &__inputPlug {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    border-radius: 5px;
    padding-inline-start: 15px;
    border: 1px solid #cbcbe2;
    color: $mediumLightGrey;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    background-color: $white;
    cursor: pointer;

    &__label {
      font-size: 16px;
    }
  }

  &__options {
    position: absolute;
    left: 0;
    right: 0;
    top: 56px;
    max-height: 180px;
    overflow-y: scroll;
    background-color: $white;
    border-radius: 5px;

    &.hidden {
      display: none;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 10px 10px;

      &__label {
        font-size: 14px;
        color: $darkGrey;
      }
    }
  }

  &__selectedOptions {
    padding-top: 10px;
    display: flex;
    font-display: row;
    align-items: center;
    flex-wrap: wrap;

    &__option {
      padding: 5px 10px;
      border: 1px solid $lighterGrey;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &__name {
        font-size: 14px;
      }
    }
  }
}
