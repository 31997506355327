@import '../../theme/mixins.scss';
@import '../../theme/palette.scss';

.TextInput .error-msg {
  @include errorMessage;
  margin-top: 0;
  margin-bottom: 5px;
}

.TextInput img {
  position: absolute;
  width: 17px;
  top: 0;
  margin-top: 24px;
  margin-inline-start: 32px;
  padding-top: 0;
}

.TextInput {
  position: relative;
  width: 100%;
  input.TextInput__input {
    font-size: 16px;
    padding: 26px 10px 10px 15px;
    display: block;
    height: 56px;

    width: 100%;
    border: 1px solid $grey;
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
  }
  input:focus {
    border-color: $lightBlue;
    border-width: 2px;
  }
  &.disabled {
    background-color: rgba(0, 0, 0, 0.07);
  }
}

.input-label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 21px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.53px;
  text-align: start;
  color: rgba(39, 39, 39, 0.4);
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.input-label {
  color: $lightGrey;
}

input:focus ~ .input-label {
  color: $primary;
}

@mixin activeLabel {
  top: 8px;
  font-size: 13px;
}

.input-label.active {
  @include activeLabel;
}

input:focus ~ .input-label,
input:disabled ~ .input-label {
  @include activeLabel;
}
